import { makeAutoObservable } from 'mobx';

class PreviewStore {
  frame = null;
  openEnv = null;
  frameUpdater = null;

  constructor() {
    makeAutoObservable(this);
  }

  closeWindow = () => {
    if (this.frame) this.frame.close();
    this.unsetFrame();
  };

  unsetFrame = () => {
    this.frame = null;
  };
}

const pStore = new PreviewStore();
window.addEventListener('unload', () => {
  pStore.closeWindow();
});

export default pStore;
