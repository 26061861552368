import React, { Fragment } from 'react';
import styled from '@emotion/styled';

const DefaultHighlight = styled.mark`
  background-color: rgba(32, 150, 243, 0.61);
  color: inherit;
  display: inline-block;
`;

const DefaultReplace = styled.span`
  background-color: #CDDC39;
  display: inline-block;
`;

const Highlight = ({
  chunks = [],
  HighlightComponent = DefaultHighlight,
  ReplaceComponent = DefaultReplace,
  WrapperComponent = 'span',
} = {}) => {
  if (!chunks) return null;
  return (
    <WrapperComponent>
      {chunks.map((c, i) => {
        if (c.replacement === undefined) return <Fragment key={i}>{c}</Fragment>;

        return (
          <Fragment key={i}>
            <HighlightComponent>{c.match}</HighlightComponent>
            <ReplaceComponent>{c.replacement}</ReplaceComponent>
          </Fragment>
        );
      })}
    </WrapperComponent>
  );
};

export default Highlight;
