import React from 'react';
import { Avatar, IconButton, Icon } from '@mui/material';
import { PropTypes } from 'prop-types';

/**
 * Displays an image or fallbacks to the two leading characters
 */
export const Favatar = ({ src, fallback }) => {
  if (src) return <Avatar alt={fallback} src={src} />;
  if (fallback) return <Avatar>{fallback.substr(0, 2)}</Avatar>;
  return null;
};

Favatar.propTypes = {
  src: PropTypes.string,
  fallback: PropTypes.string,
};

Favatar.defaultProps = {
  src: null,
  fallback: null,
};

/**
 * Displays if a repo is favorised with a star, else an outlined star
 */
export const Favorite = ({ isFavorite = false, toggleFavorite, ...restProps }) => (
  <IconButton {...restProps} onClick={toggleFavorite} size="large">
    <Icon>{isFavorite ? 'star' : 'star_outline'}</Icon>
  </IconButton>
);

Favorite.propTypes = {
  isFavorite: PropTypes.bool,
  toggleFavorite: PropTypes.func.isRequired,
};

Favorite.defaultProps = {
  isFavorite: false,
};
