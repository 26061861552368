import React from "react";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import {
  Folder,
  FolderOpen,
  KeyboardArrowDown,
  KeyboardArrowRight,
  SaveAltOutlined,
} from "@mui/icons-material";
import { groupBy } from "lodash-es";

import store from "~/store";
import fileBrowserStore from "~/store/file-browser";
import { FileTab } from "~/store/tabs";
import { SidebarBox } from "./utils";
import FileLink from "../../components/FileLink";
import { ContextItem, useContextMenu } from "~/components/ContextMenu";
import { LanguageFile } from "~/store/modules/files";

const GroupWrap = styled.div`
  text-align: left;
  > button {
    width: 100%;
    background-color: transparent;
    color: inherit;
    text-align: left;
    display: flex;
    align-items: center;
    border: 0 none;
    padding: 5px 0;

    & > * {
      margin: 0 3px;
    }

    .MuiSvgIcon-root {
      font-size: 1.2em;
      margin-right: 5px;
    }

    &:hover {
      background: #7070704f;
    }
  }

  > .content {
    margin-left: 30px;
    margin-right: 1px;
  }
`;

const File = styled(FileLink)`
  position: relative;
  display: block;
  padding: 6px;
  border-radius: 4px;
  width: 100%;
  text-align: left;
  opacity: 0.95;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    opacity: 1;
    background: #7070704f;
  }

  &[data-has-tab] {
    &::before {
      content: "";
      position: absolute;
      top: 11px;
      left: -8px;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: #9f9f9f63;
    }
  }

  &[data-active-tab] {
    background-color: #80808024;
  }

  &[data-unparsable] {
    opacity: 0.6;
    pointer-events: none;
  }
`;

// group files by path
const groupFiles = (files) => {
  const groups = groupBy(files, (file) => file.gitlabFile.dir);
  return groups;
};

// sort files by default lang
const sortFiles = (files) => {
  const p = store.currentProject;
  return files.sort((a, b) => {
    if (p.isFileDefaultLang(a) && !p.isFileDefaultLang(b)) {
      return -1;
    }
    if (!p.isFileDefaultLang(a) && p.isFileDefaultLang(b)) {
      return 1;
    }
    return a.name < b.name ? -1 : 1;
  });
};

const FileGroupActions = observer(({ files }) => {
  const hasLangFiles = files.some((f) => f instanceof LanguageFile);

  return (
    <>
      <ContextItem
        disabled={!hasLangFiles}
        onClick={() => store.actions.run("export", { files })}
      >
        <SaveAltOutlined />
        Export all as …
      </ContextItem>
    </>
  );
});

// collapsible group of files
const FileGroup = observer(({ files, name }) => {
  const [onContextMenu, ContextMenu] = useContextMenu(
    <FileGroupActions files={files} />
  );
  const open = fileBrowserStore.openFolders.has(name);
  const handleToggle = () => fileBrowserStore.toggle(name);

  // left mouse button
  const handleClick = (file) => {
    store.openFile(file);
  };

  // middle mouse button
  const handleAuxClick = (f) => {
    store.openFile(f, { focus: false });
  };

  const sorted = sortFiles(files);
  const fileLikeTabs = store.tabs.tabs.filter((t) => t instanceof FileTab) as [
    FileTab
  ];

  return (
    <GroupWrap>
      <button
        onContextMenu={onContextMenu}
        type="button"
        onClick={handleToggle}
        data-browser-folder={name}
      >
        {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        {open ? <FolderOpen /> : <Folder />}
        <span>{name}</span>
      </button>
      <ContextMenu />
      {open && (
        <div className="content">
          {sorted.map((f) => (
            <File
              className={
                store.currentProject.isFileDefaultLang(f) ? "defaultLang" : ""
              }
              file={f}
              data-browser-file={f.name}
              data-has-tab={
                fileLikeTabs.some((t) => t.file === f) ? true : undefined
              }
              data-is-default-lang={
                store.currentProject.isFileDefaultLang(f) ? true : undefined
              }
              data-active-tab={
                store.tabs.activeTab && store.tabs.activeTab.file === f
                  ? true
                  : undefined
              }
              data-unparsable={f.isParsable ? undefined : true}
              onClick={() => handleClick(f)}
              onAuxClick={() => handleAuxClick(f)}
              key={f.uid}
              noFlag
            />
          ))}
        </div>
      )}
    </GroupWrap>
  );
});

export default observer(() => {
  const grouped = groupFiles(store.currentProject.files);

  // const refresh = () => store.currentProject.fetchFileList();
  // const fetchAll = () => {
  //   const ops = store.currentProject.files.map(f => f.fetchEntries());
  //   return Promise.all(ops);
  // };

  const Tools = () => (
    <div>
      {/* <ToolButton onClick={fetchAll}><Refresh /></ToolButton> */}
      {/* <ToolButton onClick={refresh}><Refresh /></ToolButton> */}
    </div>
  );

  return (
    <>
      <SidebarBox id="filepicker" title="File Browser" tools={<Tools />}>
        {Object.keys(grouped).map((name) => (
          <FileGroup name={name} key={name} files={grouped[name]} />
        ))}
      </SidebarBox>
    </>
  );
});
