import { makeAutoObservable } from 'mobx';

const FILTER_OPS = {
  includes: (a, b) => a.includes(b),
  startsWith: (a, b) => a.startsWith(b),
  notIncludes: (a, b) => !a.includes(b),
  equals: (a, b) => a === b,
  regex: (a, regex) => regex.test(a),
};

const filterWith = (value, filter) => {
  value = filter.caseSensitive ? value : value.toLowerCase();
  let find = filter.caseSensitive ? filter.value : filter.value.toLowerCase();
  if (filter.modifier === 'regex') {
    try {
      find = new RegExp(filter.value);
    } catch {
      console.warn('invalid regex!');
      find = new RegExp('');
    }
  }
  return FILTER_OPS[filter.modifier || 'includes'](value, find);
};

class Store {
  map = new Map([
    ['id', { enabled: false, modifier: 'includes', value: '', caseSensitive: false }],
    ['value', { enabled: false, modifier: 'includes', value: '', caseSensitive: false }],
    ['markedEntries', { enabled: false }],
    ['linterEntries', { enabled: false }],
    ['modifiedEntries', { enabled: false }],
  ]);

  _filterDefaults = {};

  constructor() {
    makeAutoObservable(this);
  }

  get active() {
    return Array.from(this.map.values()).filter(e => e.enabled);
  }

  filterEntries(entries) {
    const idFilter = this.map.get('id');
    const valueFilter = this.map.get('value');
    const markedFilter = this.map.get('markedEntries');
    const linterFilter = this.map.get('linterEntries');
    const modifiedFilter = this.map.get('modifiedEntries');

    // eslint-disable-next-line complexity
    return entries.filter(e => {
      if (e.editing) return true;
      if (idFilter.enabled && !filterWith(e.id, idFilter)) return false;
      if (valueFilter.enabled && !filterWith(e.value, valueFilter)) return false;
      if (markedFilter.enabled && !e.meta.marked) return false;
      if (linterFilter.enabled && !(e.conflictsWith || e.linterInfos.length)) return false;
      if (modifiedFilter.enabled && !e.modified) return false;
      return true;
    });
  }

  get = name => {
    return this.map.get(name);
  };

  set = (name, value) => {
    const filter = this.map.get(name);
    const res = Object.assign(filter, value);
    return res;
  };

  resetAll = () => {
    this.map.forEach(filter => {
      if (filter.modifier) filter.modifier = 'includes';
      if (filter.value) filter.value = '';
      filter.enabled = false;
    });
  };
}

const store = new Store();
export default store;
