import styled from '@emotion/styled';
import { Button, Tooltip } from '@mui/material';
import { PlayCircleOutline, StopCircleOutlined } from '@mui/icons-material';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { render } from 'react-dom';
import { refreshAuthCookie } from '~/api-client';
import store from '~/store';


const CustomButton = styled(Button)`
  .material-icons { margin-right: 10px }
  margin: 0;
  padding: 10px;
  min-width: 32px;
`;

const Notification = ({ children }) => {
  const style = {
    fontSize: '12px',
    fontFamily: 'sans-serif',
    color: 'black',
    position: 'fixed',
    top: '20px',
    left: 0,
    right: 0,
    width: '300px',
    margin: '0 auto',
    borderRadius: '24px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 3px #d2d2d2',
    border: '1px solid #e8e8e8',
    padding: '13px 22px',
    zIndex: '99999',
  };

  return (
    <div style={style}>
      {children}
    </div>
  );
};

Notification.propTypes = {
  children: PropTypes.node,
};

Notification.defaultProps = {
  children: null,
};

// TODO: currentFile can be an asset file!
@observer
class PreviewButton extends React.Component {
  state = { env: null };
  async componentDidMount() {
    await refreshAuthCookie();
    const envs = await store.currentProject.fetchEnvironments();
    const found = envs.find(e => ['development', 'dev'].includes(e.slug)) || envs[0];

    if (!found) return;
    if (store.preview.openEnv === found) return;

    this.setState({
      env: found,
    });

    if (store.preview.frame) {
      this.openInNewWindow();
    }
  }
  componentWillUnmount() {
    const { preview } = store;
    if (preview.frameUpdater) store.preview.frameUpdater(); // stop reaction
    if (store.preview.frame) {
      store.preview.frame.removeEventListener('contextmenu', this._docContextMenuHandler);
      store.preview.frame.removeEventListener('unload', this._docUnloadHandler);
      store.preview.frame.removeEventListener('error', this._errorHandler);
    }
  }
  openInNewWindow = () => {
    const existing = store.preview.frame;

    const addListener = win => {
      win.addEventListener('load', () => {
        const doc = win.document;
        const node = doc.body.appendChild(doc.createElement('div'));

        setTimeout(() => {
          const editingPossible = this._featureCheck() !== false;
          const notify = (
            <Notification>
              {editingPossible === true
                ? 'Connected to TED LiveEditing™'
                : 'Project does not properly support TED LiveEditing™'
              }
            </Notification>
          );

          render(notify, node);
          if (editingPossible) {
            setTimeout(() => {
              render(null, node);
            }, 4000);
          }
          win.addEventListener('contextmenu', this._docContextMenuHandler);
          win.addEventListener('unload', this._docUnloadHandler);
          win.addEventListener('error', this._errorHandler);
          // initial apply
          this.updateTranslation(
            store.currentFile.entries.filter(e => !e.toBeRemoved && !e.creationState),
          );
        }, 100);
      });
    };

    const win = (() => {
      const project = store.currentProject.id;
      const url = `${window.location.origin}/api/v1/proxy/${project}/${this.state.env.id}`;
      if (existing) {
        existing.location = url;
        existing.addEventListener('unload', () => {
          setTimeout(() => {
            addListener(existing);
          }, 0);
        });
        return existing;
      }

      const win = window.open(url, '', 'width=800, height=900 ,resizable=yes , scrollbars=yes');
      addListener(win);
      return win;
    })();
    store.preview.frame = win;
    store.preview.openEnv = this.state.env;
    store.preview.frameUpdater = reaction(
      () => store.currentFile.entries.filter(e => !e.toBeRemoved && !e.creationState)
        .map(f => ({ id: f.id, value: f.value })),
      entries => {
        this.updateTranslation(entries);
      }, { delay: 500, fireImmediately: true },
    );
  };
  _errorHandler = e => {
    console.warn(`Error in preview page: ${e}`);
  };
  _docContextMenuHandler = e => {
    if (!e.ctrlKey) return;
    e.preventDefault();
    e.stopPropagation();


    const { entries } = store.currentFile;
    let lastId = entries[entries.length - 1].id;
    const match = /\d+$/.exec(lastId);
    if (match) {
      lastId = lastId.substr(0, match.index);
    }

    window.scrollTo(0, 0);
    let text = e.target.textContent;
    if (!text) return;
    text = text.trim();
    store.filters.set('value', { enabled: text !== '', value: text });
  };
  _docUnloadHandler = () => {
    store.preview.unsetFrame();
  };
  updateTranslation(entries) {
    const merge = {};
    entries.forEach(e => merge[e.id] = e.value);

    const win = this._window();
    if (!win) {
      console.warn('no preview window found'); // nothing to update
      return;
    }
    if (win.tedUpdateTranslation !== undefined) {
      win.tedUpdateTranslation(merge);
      return;
    }
    const doc = win.document;
    if (!doc) return;

    const app = doc.getElementById('app');
    if (!app) return;

    app.__vue__._i18n.mergeLocaleMessage(app.__vue__._i18n.locale, merge);
  }
  _featureCheck() {
    if (!store.preview.frame) return false; // no window open
    const win = this._window();
    if (!win) return false; // no window reference
    if (win.tedUpdateTranslation !== undefined) return true; // found method on the window
    const app = win.document.getElementById('app');
    if (!app) return false; // no app

    return (app && app.__vue__ && app.__vue__.i18n);
  }
  _doc() {
    return store.preview.frame.document;
  }
  _window() {
    return store.preview.frame;
  }
  render() {
    if (!this.state.env) {
      return (
        <Tooltip placement="right" title="Live preview not supported">
          <div>
            <CustomButton disabled onClick={this.openInNewWindow}>
              <PlayCircleOutline />
            </CustomButton>
          </div>
        </Tooltip>
      );
    }

    if (!store.preview.frame) {
      return (
        <Tooltip placement="right" title="Start live preview">
          <CustomButton onClick={this.openInNewWindow}>
            <PlayCircleOutline />
          </CustomButton>
        </Tooltip>
      );
    }
    return (
      <Tooltip placement="right" title="Stop live preview">
        <CustomButton onClick={() => store.preview.closeWindow()}>
          <StopCircleOutlined />
        </CustomButton>
      </Tooltip>
    );
  }
}

export default PreviewButton;
