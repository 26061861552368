import { VerifiedTwoTone } from "@mui/icons-material";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";

import store, { FileTab } from "~/store";
import LanguageFile from "~/store/modules/files/language-file";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { AISuggestionTab } from "~/store/tabs";
import { Avatar, Skeleton } from "@mui/material";
import { motion } from "framer-motion";
import { AIChatMessage, AISuggestionEntry } from "~/store/ai";
import {
  ArrowUpSquareIcon,
  BotIcon,
  ChevronDownIcon,
  ListRestart,
  ShieldCheckIcon,
} from "lucide-react";
import { chatPrompt, proofRead, toolbox } from "~/lib/ai";
import { cn } from "~/lib/utils";

function Task({ icon, title, onClick }) {
  return (
    <li>
      <button
        onClick={onClick}
        className="w-full text-left hover:bg-blue-100 hover:dark:bg-blue-800 rounded-lg p-1 px-2 flex items-center gap-2"
      >
        <div className="bg-blue-50 dark:bg-zinc-600 rounded p-1">{icon}</div>
        <div className="flex-1 font-semibold">{title}</div>
      </button>
    </li>
  );
}

const KeyLink = ({ file, keyName }) => {
  const handleClick = async () => {
    // find entry
    const tab = (await store.openFile(file, { focus: true })) as FileTab;
    const langFile = tab.file as LanguageFile;
    const entry = langFile.entries.find((e) => e.id === keyName);

    if (!entry) return;
    store.findReplace.focusedIndex = langFile.entries.indexOf(entry);
  };
  return (
    <button
      onClick={handleClick}
      className="bg-gray-300 dark:bg-slate-800 hover:bg-blue-200 hover:dark:bg-blue-800 rounded-lg p-1 inline-flex items-center gap-2"
    >
      <div className="flex-1 text-sm font-semibold max-w-24 truncate [direction:rtl]">
        {keyName}
      </div>
    </button>
  );
};

const ChangeSuggestion = ({
  suggestion,
  file,
}: {
  suggestion: Record<string, string>;
  file: LanguageFile;
}) => {
  const suggestions = Object.entries(suggestion)
    .map(([key, value]) => {
      const originalEntry = file.entries.find((e) => e.id === key);
      if (!originalEntry) return null;
      return new AISuggestionEntry({ key, value, originalEntry });
    })
    .filter((e) => e && e.value !== e.originalValue);
  const len = suggestions.length;

  const handleClick = () => {
    store.tabs.tabs.push(
      new AISuggestionTab({
        file,
        suggestions,
        label: `AI: ${file.name}`,
        id: `ai-suggestion-${file.uid}`,
      })
    );
    store.tabs.activeTab = store.tabs.tabs[store.tabs.tabs.length - 1];
  };

  const variants = {
    hidden: { opacity: 0, translateX: -10, scale: 0.95 },
    visible: { opacity: 1, translateX: 0, scale: 1 },
  };

  return (
    <motion.div
      variants={variants}
      className="flex font-sans items-center justify-between gap-2 bg-white dark:bg-zinc-800 dark:border-zinc-700 border border-gray-300 rounded p-2 my-2"
    >
      <div className="text-lg px-2">
        {len} Suggestion{len > 1 ? "s" : ""}
      </div>
      <div className="flex gap-2">
        {/* <button className="bg-gray-100 hover:bg-gray-200 text-gray-500 font-semibold py-1 px-2 rounded">
          Apply
        </button> */}
        <button
          onClick={handleClick}
          className="bg-purple-500 hover:bg-purple-700 text-white font-semibold py-2 px-4 rounded"
        >
          View
        </button>
      </div>
    </motion.div>
  );
};

const Responses = observer(
  ({
    responses,
    onAnimationEnd,
  }: {
    responses: AIChatMessage[];
    onAnimationEnd?: () => void;
  }) => {
    const components = {
      code: ({ className, children }) => {
        if (className && className.includes("language-json")) {
          // try to parse as json
          try {
            const parsed = JSON.parse(children);
            return (
              <ChangeSuggestion
                suggestion={parsed}
                file={store.currentFile as LanguageFile}
              />
            );
          } catch (e) {
            console.error(e);
          }
        }
        return <code>{children}</code>;
      },
      key: ({ children }) => {
        return (
          <KeyLink file={store.currentFile} keyName={children as string} />
        );
      },
      file: ({ children }) => {
        return <span className="font-bold">{children}</span>;
      },
      p: ({ className, children }) => {
        const variants = {
          hidden: { opacity: 0, translateX: -10, scale: 0.95 },
          visible: { opacity: 1, translateX: 0, scale: 1 },
        };

        return (
          <motion.p variants={variants} className={className}>
            {children}
          </motion.p>
        );
      },
      ul: ({ className, children }) => {
        const variants = {
          hidden: { opacity: 0, translateX: -10, scale: 0.95 },
          visible: { opacity: 1, translateX: 0, scale: 1 },
        };

        return (
          <motion.ul variants={variants} className={className}>
            {children}
          </motion.ul>
        );
      },
      context: () => null,
    };

    return (
      <>
        {responses.map((response) => (
          <div
            key={response.id}
            className="bg-gray-200 dark:bg-slate-700 rounded-lg p-3 text-sm py-0 "
          >
            <div className="py-2">
              {response.from === "user" ? (
                <div className="opacity-90 pr-2 rounded-lg items-center gap-2 bg-blue-100 dark:bg-slate-600 p-1 inline-flex">
                  <Avatar className="!h-5 !w-5" />
                  {store.user.name}
                </div>
              ) : (
                <div className="opacity-90 pr-2 rounded-lg items-center gap-2 bg-purple-200 dark:bg-slate-600 p-1 inline-flex">
                  <BotIcon className="h-5 w-5" />
                  TED AI
                </div>
              )}
            </div>

            <motion.div
              initial={response.from === "user" ? "visible" : "hidden"}
              animate="visible"
              variants={{
                hidden: {},
                visible: {
                  transition: {
                    staggerChildren: 0.1,
                    delayChildren: 0.3,
                  },
                },
              }}
              onAnimationEnd={onAnimationEnd}
            >
              <Markdown
                rehypePlugins={[rehypeRaw]}
                components={components}
                className="text-content"
              >
                {response.content}
              </Markdown>
            </motion.div>
          </div>
        ))}
      </>
    );
  }
);

export default observer(() => {
  const [quickPanelOpen, setQuickPanelOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const chatContainer = useRef<HTMLDivElement>(null);
  function scrollToBottom() {
    setTimeout(() => {
      chatContainer.current?.scrollTo({
        top: chatContainer.current?.scrollHeight,
        behavior: "smooth",
      });
    }, 100);
  }

  const chat = store.currentChat;
  const [input, setInput] = useState("");

  if (!chat) {
    return (
      <div className="flex bg-gray-400/20 rounded-lg flex-col gap-4 items-center justify-center p-8">
        <BotIcon className="h-12 w-12 opacity-50" />
        <p className="opacity-80">
          Open a text file to start chatting with TED AI.
        </p>
      </div>
    );
  }

  const suggestResult = (suggestion: Record<string, string>) => {
    const text = [
      "Here is the suggested change:",
      "```json",
      JSON.stringify(suggestion, null, 2),
      "```",
    ]
      .join("\n")
      .trim();

    chat.addMessage({ content: text, from: "ai" as const });
  };

  const generate = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const currentFile = store.currentFile;

    if (currentFile instanceof LanguageFile) {
      const response = await chatPrompt(chat);
      const data = {
        content: response.output,
        tokenCount: response.tokenCount,
        from: "ai" as const,
      };
      chat.addMessage(data);

      scrollToBottom();
      setIsLoading(false);

      return;
    }
  };

  const addMessage = (message: string) => {
    // add text
    chat.addMessage({
      content: message,
      from: "user",
    });
    setInput("");
    scrollToBottom();

    generate();
  };

  const customAction = async (
    input: string,
    actionFunc: () => Promise<AIChatMessage>
  ) => {
    const chat = store.currentChat;
    if (!chat) return;

    chat.addMessage({
      content: input,
      from: "user",
    });
    scrollToBottom();
    setIsLoading(true);

    const msg = await actionFunc();

    setIsLoading(false);
    chat.addMessage(msg);
    scrollToBottom();
  };

  const chatProofRead = async (mode: "single" | "merge" | "bulk") => {
    const fn = async () => {
      const result = await proofRead(store.currentFile as LanguageFile, mode);
      const out = [
        "Here is the proofread result:",
        "```json",
        JSON.stringify(result, null, 2),
        "```",
      ].join("\n");
      return new AIChatMessage({ content: out, from: "ai" as const });
    };
    return customAction(`Proofread this file.`, fn);
  };

  const handleFormSubmission = async (
    event: React.FormEvent<HTMLFormElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();

    addMessage(input);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      // submit form
      handleFormSubmission(event);
    }
  };

  return (
    <div
      className="flex px-2 h-full flex-col justify-between overflow-y-auto"
      ref={chatContainer}
    >
      <div className="sticky rounded-lg bg-white dark:bg-zinc-950 shadow top-0 z-10">
        <div className="flex p-1 rounded justify-between item-center z-10">
          <div className="rounded-lg bg-gray-400/20 flex">
            <button
              onClick={() => addMessage("Check for spelling mistakes")}
              className="px-2 rounded hover:bg-gray-400/20"
            >
              <VerifiedTwoTone />
            </button>
            <button
              onClick={() => chatProofRead("bulk")}
              className="px-2 rounded hover:bg-gray-400/20"
            >
              <ShieldCheckIcon />
            </button>
            <button
              className="px-2 rounded hover:bg-gray-400/20"
              onClick={() => addMessage("Improve this file")}
            >
              <ArrowUpSquareIcon />
            </button>
            <button
              className={cn(
                "px-2 rounded hover:bg-gray-400/20",
                quickPanelOpen && "rotate-180"
              )}
              onClick={() => setQuickPanelOpen(!quickPanelOpen)}
            >
              <ChevronDownIcon />
            </button>
          </div>
          <div></div>
          <button
            onClick={() => chat.clear()}
            className="p-1 rounded-lg hover:bg-gray-400/20"
          >
            <ListRestart />
          </button>
        </div>
        {quickPanelOpen && (
          <ul className="space-y-2 z-10 p-1">
            {/* <li>Add a new language</li>
          <li>Add a new asset</li> */}

            <Task
              icon={<VerifiedTwoTone />}
              title="Check for spelling mistakes"
              onClick={() => addMessage("Check for spelling mistakes")}
            />

            <Task
              icon={<ShieldCheckIcon />}
              title="Proofread"
              onClick={() => chatProofRead("merge")}
            />

            <Task
              icon={<ArrowUpSquareIcon />}
              title="Improve"
              onClick={() => addMessage("Improve this file")}
            />

            <hr className="dark:border-zinc-700" />

            <Task
              icon={<ShieldCheckIcon />}
              title="Proofread (AI Toolbox)"
              onClick={() => chatProofRead("bulk")}
            />

            <Task
              icon={<ArrowUpSquareIcon />}
              title="Improve (AI Toolbox)"
              onClick={() =>
                toolbox(store.currentFile as LanguageFile, "improve").then(
                  suggestResult
                )
              }
            />

            {/* <Task
            icon={<ReviewsTwoTone />}
            title="Critique this file"
            description="Critique this file."
          /> */}
            {/* <Task
            icon={<QueryStatsTwoTone />}
            title="Find outliers"
            description="Find outliers in this file."
          /> */}
          </ul>
        )}
      </div>
      <div className="flex-1"></div>
      <div className="space-y-4 mb-2">
        <Responses responses={chat.messages} />
        {isLoading && (
          <div className="bg-gray-200 dark:bg-slate-700 rounded-lg p-3 text-sm py-0 ">
            <div className="py-2">
              <div className="opacity-90 pr-2 rounded-lg items-center gap-2 bg-purple-200 dark:bg-slate-600 p-1 inline-flex">
                <BotIcon className="h-5 w-5" />
                TED AI
              </div>
              <Skeleton
                className="rounded-lg mt-4"
                variant="rectangular"
                width="100%"
                height="20px"
              />
              <Skeleton
                className="rounded-lg mt-2"
                variant="rectangular"
                width="80%"
                height="20px"
              />
            </div>
          </div>
        )}
      </div>
      <form onSubmit={handleFormSubmission} className="sticky bottom-0">
        <textarea
          value={input}
          disabled={isLoading}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Ask TED AI about this file"
          className=" border dark:border-zinc-500 bg-white dark:bg-gray-900 z-10 rounded-lg p-2 w-full h-12 shadow"
        />
      </form>
    </div>
  );
});
