import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const DefaultHighlight = styled.mark`
  background-color: rgba(32, 150, 243, 0.61);
  color: inherit;
  display: inline-block;
`;

const DefaultReplace = styled.span`
  background-color: #CDDC39;
  display: inline-block;
`;

const LegacyHighlight = props => {
  const { search, text, WrapperComponent } = props;
  if (search.length === 0) return <WrapperComponent>{text}</WrapperComponent>;

  const {
    caseSensitive,
    replace,
    escaped,
    HighlightComponent,
    ReplaceComponent,
  } = props;

  // TODO: Improve algo performance
  const flag = caseSensitive ? 'g' : 'gi';
  const escapedSearch = escaped ? search : search.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  const regexp = new RegExp(`(${escapedSearch})`, flag);
  const chunks = text.split(regexp);

  return (
    <WrapperComponent>
      {chunks.filter(chunk => chunk.length)
        .map((chunk, i) => (regexp.test(chunk) // TODO: check can be made redundant
          ? (
            <Fragment key={i}>
              <HighlightComponent>{chunk}</HighlightComponent>
              {replace && <ReplaceComponent>{replace}</ReplaceComponent>}
            </Fragment>
          )
          : chunk))}
    </WrapperComponent>
  );
};

const tagPropType = PropTypes.any;

LegacyHighlight.propTypes = {
  text: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  caseSensitive: PropTypes.bool,
  escaped: PropTypes.bool,
  HighlightComponent: tagPropType,
  replace: PropTypes.string,
  ReplaceComponent: tagPropType,
  WrapperComponent: tagPropType,
};

LegacyHighlight.defaultProps = {
  caseSensitive: false,
  escaped: false,
  HighlightComponent: DefaultHighlight,
  replace: '',
  ReplaceComponent: DefaultReplace,
  WrapperComponent: 'span',
};

export default LegacyHighlight;
