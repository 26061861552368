import React from 'react';
// import { observer } from 'mobx-react';
import styled from '@emotion/styled';

import { Link } from 'react-router-dom';
import {
  IconButton,
} from '@mui/material';
import { HomeOutlined } from '@mui/icons-material';

import ProjectMenu from '../ProjectMenu';

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  min-height: 44px;
  padding-left: 8px;
  overflow: hidden;
  z-index: 5;
  font-weight: bold;
  letter-spacing: 1px;

  .home {
    margin-left: 3px;
    margin-right: 12px;
  }
`;

export default ({ shrunken = false }) => {
  return (
    <HeaderWrap>
      <IconButton className="home" color="inherit" component={Link} to="/" size="large">
        <HomeOutlined />
      </IconButton>
      {shrunken ? null : <ProjectMenu />}
    </HeaderWrap>
  );
};
