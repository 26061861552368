import { makeAutoObservable, toJS } from 'mobx';
import localForage from 'localforage';
import xxh from 'xxhashjs';
import lang from '@hydrant/lang-conversion';
import { GitlabFile } from '~/api-client';
import Entry from '../entry';
import filters from '../../filters';
import { entriesToHtml } from '../../utils/markdown-html-export';

import { getCountryFromPath, type Openable } from './core';

class ParseError extends Error {
  constructor(error: Error) {
    super(error.message);
    this.stack = error.stack;
    this.name = 'ParseError';
  }
}

export default class LanguageFile implements Openable {
  gitlabFile: GitlabFile;
  loaded = false;

  _entries: Entry[] = [];

  lastCommitId: string;
  includeInCommit = true;

  parseError = null;
  scrollTop = 0;

  lastAutosave = null;

  constructor(file: GitlabFile) {
    this.gitlabFile = file;

    makeAutoObservable(this);
  }

  get id() {
    console.warn('id is deprecated, use uid instead');
    return this.gitlabFile.uid;
  }

  get uid() {
    return this.gitlabFile.uid;
  }

  get name() {
    return this.gitlabFile.name;
  }

  get country() {
    return getCountryFromPath(this.gitlabFile.path);
  }

  // directory is the path to the file (without the filename)
  get directory() {
    return this.gitlabFile.path.split('/').slice(0, -1).join('/');
  }

  get entries() {
    return this._entries;
  }

  set entries(_) {
    throw new Error('Cannot set entries directly');
  }

  // At the moment we only include parsable files anyways
  // but this is here for future proofing maybe
  get isParsable() {
    return true;
  }

  get filteredEntries() {
    return filters.filterEntries(this.entries);
  }

  // entry related
  get flaggedEntries() {
    return this.entries.filter(e => e.meta.marked);
  }

  get modifiedEntries() {
    return this.entries.filter(e => e.modified);
  }

  get conflictingEntries() {
    return this.entries.filter(e => e.conflictsWith);
  }

  get modifyCount() {
    return this.modifiedEntries.length;
  }

  get linterProblemCount() {
    return this.entries.filter(e => e.linterInfos.length).length;
  }

  get criticalProblemCount() {
    return (
      (this.conflictingEntries.length || 0)
      + (this.duplicateKeys.length || 0)
    );
  }

  get problemCount() {
    return (
      this.criticalProblemCount
      + (this.linterProblemCount || 0)
    );
  }

  get modifiedAnything() {
    return this.modifyCount > 0;
  }

  get hasConflicts() {
    return this.conflictingEntries.length > 0 || this.duplicateKeys.length > 0;
  }

  get keys() {
    return this.entries.map(entry => entry.id);
  }

  get duplicateKeys() {
    const countedKeys = this.entries
      .filter(e => !e.toBeRemoved)
      .map(entry => entry.id)
      .reduce((counts, key) => Object.assign(counts, { [key]: (counts[key] || 0) + 1 }), {});

    return Object.keys(countedKeys).filter(key => countedKeys[key] > 1);
  }

  get stats() {
    const activeEntries = this.entries
      .filter(e => !e.toBeRemoved);

    const words = activeEntries.reduce(
      (sum, entry) => sum += !entry.value ? 0 : entry.value.split(' ').filter(w => w.length).length,
      0,
    );

    return {
      entries: activeEntries.length,
      words,
    };
  }

  // calculates the hash of the entries in this file
  get contentHash() {
    const hash = xxh.h32();
    this.entries.forEach(entry => {
      hash.update([
        entry.id,
        entry.value,
        entry.toBeCreated,
        entry.toBeRemoved,
        entry.meta.marked,
      ].join(':'));
    });

    return hash.digest().toString(16);
  }

  private newEntry(id, value?) {
    const entry = new Entry(id, value);
    entry.parentFile = this;
    return entry;
  }

  validateEntries(entries = this.entries) {
    entries.forEach((entry, i) => {
      if (typeof entry.value !== 'string') {
        throw new Error(`Value for entry "${entry.id}" (index ${i}) must be a string`);
      }
    });
  }

  markCommitted(id?: string) {
    this.lastCommitId = id;
    this.entries.forEach(entry => entry.markSaved());
    // remove removed entries
    const entries = this.entries
      .filter(entry => entry.toBeRemoved === false);
    this.setEntries(entries);
  }

  async undoChanges() {
    await this.fetchEntries();
    await this.autosave();
  }

  setEntries(entries) {
    try {
      this.validateEntries(entries);
    } catch (e) {
      this.parseError = e;
      return;
    }
    this._entries = entries;
    this.parseError = null;
    this.loaded = true;
  }

  async _fetchFromGitlab(ref = this.gitlabFile.ref) {
    this.parseError = null;
    const res = await this.gitlabFile.fetch(ref);

    const parsed = await lang.parseGitlabFile(res).catch(e => {
      this.parseError = e;
      throw new ParseError(e);
    });
    return parsed;
  }

  async fetchEntries(ref = 'master') {
    const parsed = await this._fetchFromGitlab(ref);
    // bit of a hack to persist marked entries between resets
    const entries = parsed.entries.map(e => this.newEntry(e.id, e.value === null ? '' : e.value));
    await this.restoreMarked(entries);
    this.setEntries(entries);
    return this.entries;
  }

  async mergeRemote(ref = 'master') {
    const parsed = await this._fetchFromGitlab(ref);
    this.setEntries(this.merge(this.entries, parsed.entries));
  }

  replaceWith(newLines) {
    // map of old entires
    const currentMap = new Map();
    this.entries.forEach(line => currentMap.set(line.id, line));

    const usedKeys = new Set();
    const newEntries = newLines.map((line, newIndex) => {
      const match = currentMap.get(line.id);
      if (!match || usedKeys.has(line.id)) {
        return new Entry({
          id: line.id,
          value: line.value,
          toBeCreated: true,
          imported: true,
        });
      }
      usedKeys.add(line.id);
      const origIndex = this.entries.indexOf(match);
      if (origIndex !== newIndex) match.original.index = origIndex;

      match.setValue(line.value);
      return match;
    });

    const removedEntries = this
      .entries.filter(e => !newLines.some(newEntry => newEntry.id === e.id));

    removedEntries.forEach(e => e.markRemoved());

    this.setEntries([...removedEntries, ...newEntries]);
  }

  merge(oldEntries, newEntries) {
    // map of new and old entries
    const oldMap = new Map();
    oldEntries.forEach(line => oldMap.set(line.id, line));

    const newMap = new Map();
    newEntries.forEach(line => newMap.set(line.id, line));

    // first update all existing lines
    const result = [];
    oldEntries.forEach(entry => {
      const match = newMap.get(entry.original.id || entry.id);
      if (match) {
        match._applied = true;
        result.push(entry.remoteUpdate(match));
      } else {
        // existing entry that has been removed upstream
        if (entry.toBeCreated !== true && entry.toBeRemoved !== true) {
          entry.remoteUpdated = true;
          entry.conflictsWith = this.newEntry({
            value: entry.original.value,
            id: entry.id,
            toBeRemoved: true,
          });
        }
        result.push(entry);
      }
    });

    // now add any missing lines
    newEntries.forEach((line, i) => {
      if (line._applied) return;
      const entry = this.newEntry(line.id, line.value);
      entry.remoteUpdated = true;
      if (i > result.length - 1) result.push(entry);
      else result.splice(i, 0, entry);
    });

    return result;
  }

  async restoreMarked(entries = this.entries) {
    const items = await localForage.getItem(`ted.autosave.marked.v1.${this.uid}`) as any[];
    if (!items) return entries;

    items.forEach(id => {
      const entry = entries.find(e => e.id === id);
      if (entry) entry.meta.marked = true;
    });
    return entries;
  }

  // autosave persists the file content if needed and deletes
  // the local copy otherwise
  async autosave() {
    const newHash = this.contentHash;
    // autosave requested but nothing changed? we delete the local copy
    if (this.modifiedAnything === false) {
      return this.deleteLocal();
    }
    if (this.lastAutosave?.localHash === newHash) return Promise.resolve();
    const toSave = this.entries.map(e => ({
      id: e.id,
      value: e.value,
      toBeCreated: e.toBeCreated,
      toBeRemoved: e.toBeRemoved,
      original: toJS(e.original),
    }));
    await localForage.setItem(`ted.autosave.${this.uid}.content`, toSave);

    const save = {
      schemaVersion: 1,
      commit: this.lastCommitId,
      date: new Date(),
      localHash: newHash,
    };
    await localForage.setItem(`ted.autosave.${this.uid}.meta`, save);
    this.lastAutosave = save;
    return save;
  }

  async deleteLocal() {
    await localForage.removeItem(`ted.autosave.${this.uid}.content`);
    await localForage.removeItem(`ted.autosave.${this.uid}.meta`);
    this.lastAutosave = null;
  }

  _v0AutosaveLoad = async (autosave, content) => {
    await this.fetchEntries(autosave.commit);
    this.replaceWith(content.map(item => new Entry({ id: item[0], value: item[1] })));

    await this.restoreMarked(this.entries);
    await this.mergeRemote();
  };

  async loadAutosave() {
    const autosave = await localForage.getItem(`ted.autosave.${this.uid}.meta`) as any;
    if (!autosave) return;
    const content = await localForage.getItem(`ted.autosave.${this.uid}.content`) as any;
    if (!content) return;

    let entries = [];
    // support v0 autosave
    if (content.length && content[0] instanceof Array) {
      console.warn('v0 migration');
      await this._v0AutosaveLoad(autosave, content);
      // overwrite v0 autosave
      await this.autosave();
      return;
    }

    // v1 autosave
    entries = content.map(entry => this.newEntry(entry));

    this.lastAutosave = autosave;
    this.lastCommitId = autosave.commit;
    entries = await this.restoreMarked(entries);
    // merge remote updates
    const upstream = await this._fetchFromGitlab();
    entries = this.merge(entries, upstream.entries);
    // finally set the entries
    this.setEntries(entries);
  }

  async load() {
    if (this.loaded) {
      console.debug(`[${this.name}] already loaded`);
      return;
    }
    console.debug(`[${this.name}] loading`);
    try {
      await this.loadAutosave();
      if (this.loaded) {
        console.debug(`[${this.name}] loaded autosave`);
      } else {
        console.debug(`[${this.name}] has no autosave, loading from remote`);
        await this.fetchEntries();
      }
      return;
    } catch (e) {
      if (e instanceof ParseError) {
        this.loaded = true;
        return;
      }
      throw e;
    }
  }

  setScrollTop(top = 0) {
    this.scrollTop = top;
  }

  async serializedEntriesAs(ext) {
    const entries = this.entries.filter(e => e.toBeRemoved !== true);

    if (ext === 'html') {
      return entriesToHtml(entries);
    }

    return lang.exportFile({ ext, file_name: this.name }, entries)
      .then(f => f.content);
  }

  async serializedEntries() {
    return this.serializedEntriesAs(this.gitlabFile.fileType);
  }
}

