import lang from '@hydrant/lang-conversion';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

const Import = observer(({ file, onDone }) => {
  const uploadFile = event => {
    const { target } = event;
    // no file
    if (target.files.length <= 0) {
      console.log('no file');
      onDone();
      return;
    }

    const reader = new FileReader();
    const fileName = target.files[0].name;

    reader.onload = (async event => {
      const parsed = await lang.parseFile({
        file_name: fileName,
        content: event.target.result,
      }).catch(e => e);

      // clear the file input after parsing
      target.value = '';

      if (parsed instanceof Error) {
        toast.error(`Import failed. ${parsed}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }

      let fail = false;
      for (let i = 0; i < parsed.entries.length; i++) {
        const entry = parsed.entries[i];
        if (entry.value && typeof entry.value !== 'string') {
          toast.error(`Import failed: "${entry.id}" contains invalid value (not a string)`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          fail = true;
          break;
        }
      }

      if (fail) return;

      file.replaceWith(parsed.entries);
      onDone();
    });

    if (fileName.endsWith('.xlsx')) {
      reader.readAsArrayBuffer(event.target.files[0]);
    } else {
      reader.readAsText(event.target.files[0]);
    }
  };

  const input = React.useRef();

  useEffect(() => {
    if (input.current) {
      input.current.focus();
      input.current.click();
    }
  }, [input.current]);

  return (
    <input
      ref={input}
      onChange={uploadFile}
      style={{ display: 'none' }}
      id="import-file-button"
      type="file"
    />
  );
});

Import.display = 'raw';

export default Import;
