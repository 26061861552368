import {
  makeObservable,
  observable,
  computed,
  makeAutoObservable,
  action,
} from "mobx";
import move from "array-move";
import { File, LanguageFile } from "./modules/files";
import { AISuggestionEntry } from "./ai";

class Tab {
  id = "";
  label = "";

  constructor(tab) {
    this.id = tab.id || tab.file?.uid;
    this.label = tab.label;
  }
}

export class FileTab extends Tab {
  file: File = null;

  get scrollTop() {
    if (this.file instanceof LanguageFile) {
      return this.file.scrollTop;
    }
    return 0;
  }

  constructor(tab) {
    super(tab);
    this.file = tab.file;
    makeObservable(this, {
      file: observable,
      scrollTop: computed,
    });
  }
}

export class AISuggestionTab extends Tab {
  file: LanguageFile = null;
  suggestions: AISuggestionEntry[] = [];
  rawSuggestion = "";

  constructor(tab) {
    super(tab);
    this.rawSuggestion = tab.rawSuggestion;
    this.suggestions = tab.suggestions;
    this.file = tab.file;
    makeObservable(this, {
      file: observable,
      suggestions: observable,
      acceptAll: action,
      revertAll: action,
    });
  }

  acceptAll() {
    this.suggestions.forEach((s) => s.apply());
  }

  revertAll() {
    this.suggestions.forEach((s) => s.revert());
  }
}

export class DiffViewTab extends Tab {
  file: LanguageFile = null;

  constructor(tab) {
    super(tab);
    this.file = tab.file;
    makeObservable(this, {
      file: observable,
      title: computed,
    });
  }

  get title() {
    return `Diff: ${this.file.name}`;
  }
}

class Store {
  tabs: Tab[] = [];
  activeTab = null;

  constructor() {
    makeAutoObservable(this);
  }

  get fileTabs() {
    return this.tabs.filter((t) => t instanceof FileTab) as [FileTab];
  }

  add(tab: Tab, focus = true) {
    this.tabs.push(tab);
    if (focus) this.activeTab = tab;
  }

  removeTab(tab) {
    const index = this.tabs.indexOf(tab);
    if (index > -1) {
      this.tabs.splice(index, 1);
    }
    if (tab === this.activeTab) {
      const newActiveTab = this.tabs[index > 0 ? index - 1 : 0];
      if (newActiveTab) this.activeTab = newActiveTab;
      else this.activeTab = null;
    }
  }

  removeAllTabs() {
    this.tabs = [];
    this.activeTab = null;
  }

  moveTabIndex(oldIndex, index) {
    this.tabs = move(this.tabs, oldIndex, index);
  }

  openFileTab(file: File, focus = true) {
    const existingTab = this.tabs.find((t) => t.id === file.uid);
    if (existingTab) {
      this.activeTab = existingTab;
      return existingTab;
    }

    const newTab = new FileTab({
      id: file.uid,
      label: file.name,
      file,
    });
    newTab.file.load();
    this.tabs.push(newTab);
    console.debug("openFileTab", newTab);

    // set tab if focus is requested or this is the only file
    if (focus || this.activeTab === null) {
      this.activeTab = newTab;
    }
    return newTab;
  }
}

const store = new Store();
export default store;
