import React from 'react';
import styled from '@emotion/styled';
import { LinearProgress } from '@mui/material';

const Splash = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px);
  padding: 20vh;
  overflow: hidden;
  color: gray;

  .logo {
    margin-bottom: 1.5em;
  }

  .content {
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .spinner {
    width: 100%;
  }
`;

export default ({ message }) => {
  return (
    <Splash>
      <h3 className="mb-3">{message}</h3>
      <div className="content">
        <img className="logo" src="/static/appicon/android-chrome-192x192.png" alt="" />
        <LinearProgress className="spinner" />
      </div>
    </Splash>
  );
};
