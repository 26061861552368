import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { observer } from 'mobx-react';

const Bubble = styled.div`
  background-color: #FF5722;
  width: 1.5em;
  height: 1.5em;
  border-radius: 5px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

@observer
export class LintList extends React.Component {
  state = { preventRender: false };
  delayTimer = null;
  static propTypes = {
    entry: PropTypes.object.isRequired,
  };
  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      preventRender: state.preventRender || props.entry.linterInfos.length === 0,
    };
  }
  componentDidUpdate() {
    const warnings = this.props.entry.linterInfos.length;
    if (warnings !== 0 && this.state.preventRender) {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.setState({ preventRender: false });
      }, 1000);
    }
  }
  render() {
    const { entry, ...rest } = this.props;
    const infos = entry.linterInfos;
    // "debounce"
    if (this.state.preventRender) return false;
    const Wrapper = styled.div`
      background-color: #ff7043;
      color: white;
      border-radius: inherit;
      overflow: hidden;
      table {
        contain: content;
        width: 100%;
        th {
          background-color: #FF5722;
          text-align: left;
          padding: 5px;
        }
        td { padding: 5px; }
      }
    `;

    if (!infos.length) return null;
    return (
      <Paper {...rest}>
        <Wrapper>
          <table cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                <th>Severity</th>
                <th>Message</th>
                <th>Char</th>
              </tr>
            </thead>
            <tbody>
              {infos.map((info, i) => {
                return (
                  <tr key={i}>
                    <td>{info.level}</td>
                    <td>{info.msg}</td>
                    <td>{info.char}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Wrapper>
      </Paper>
    );
  }
}

export const LintBubble = observer(props => {
  const { entry } = props;
  const count = entry.linterInfos.length;
  if (count === 0) return null;
  return <Bubble {...props}>{count}</Bubble>;
});

LintBubble.propTypes = {
  entry: PropTypes.object.isRequired,
};

