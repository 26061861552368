import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import {
  Button,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  DownloadOutlined,
  InfoOutlined,
  OpenInNewOutlined,
  Undo,
} from '@mui/icons-material';
import axios from 'axios';
import prettyBytes from 'pretty-bytes';
import { toast } from 'react-toastify';

import RatioBox from '~/components/RatioBox';
import api from '~/api-client';
import store from '~/store';
import Asset from '~/store/modules/asset';
import Check, { checkOk, checkWarn } from './checks';
import CropDialog from './CropDialog';

const ACCEPT_MAP = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  webp: 'image/webp',
  svg: 'image/svg+xml',
};

const AssetWrap = styled.li`
  padding: 20px;
  display: flex;
  border-bottom: 1px solid rgb(0 0 0 / 12%);
  position: relative;

  .preview {
    max-width: 400px;
    margin-right: 20px;
    flex: 1;
  }

  .basicFileDesc {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .actualDesc {
    margin-bottom: 10px;
  }

  .specs {
    flex: 1;
    border-left: 1px solid rgb(0 0 0 / 12%);
    padding-left: 20px;
  }

  img, video {
    width: 100%;
  }

  .specsHeadline {
    background-color: rgb(33 150 243 / 8%);
    display: inline-block;
    padding: 5px 16px;
    border-radius: 20px;
  }

  .warning {
    padding: 15px 0;
    display: flex;
    align-items: center;
  }

  .warning .MuiSvgIcon-root {
    margin-right: 10px;
  }

  .changeDot {
    background-color: var(--theme-warn);
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    left: 6px;
  }
`;

const TitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;

  h3 {
    font-size: 1.5em;
    margin: 0;
    padding-right: 20px;
  }

  .replaceAssetAction {
    margin-left: 10px;
  }
`;

const NoteBoxWrap = styled.div`
  background-color: #ffe8ce;
  color: #744b00;
  border-radius: 6px;
  margin-bottom: 10px;
  font-size: 0.8em;
  position: relative;
  margin: 10px 0;

  .noteIcon {
    margin-right: 10px;
    position: absolute;
    left: -9px;
    background-color: inherit;
    color: black;
    border-radius: 100%;
    padding: 4px;
  }

  .noteBoxContent {
    padding: 10px 20px;
  }
`;

const NoteBox = ({ children }) => (
  <NoteBoxWrap>
    <InfoOutlined className="noteIcon" />
    <div className="noteBoxContent">
      {children}
    </div>
  </NoteBoxWrap>
);

const PreviewElement = ({ entry, onLoaded }) => {
  const ref = React.useRef<HTMLImageElement | HTMLVideoElement>();
  const [loaded, setLoaded] = React.useState(false);

  const handleLoaded = () => {
    const img = ref.current;
    let width;
    let height;
    if (img instanceof HTMLImageElement) {
      width = img.naturalWidth;
      height = img.naturalHeight;
    }
    if (img instanceof HTMLVideoElement) {
      width = img.videoWidth;
      height = img.videoHeight;
    }
    setLoaded(true);
    onLoaded(width, height);
  };
  if (entry.content.path.endsWith('mp4')) {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        className={loaded ? 'loaded' : ''}
        onLoad={handleLoaded}
        ref={n => ref.current = n}
        controls
        src={entry.preview}
      />
    );
  }
  return (
    <img
      className={loaded ? 'loaded' : ''}
      onLoad={handleLoaded}
      ref={n => ref.current = n}
      src={entry.preview}
      alt=""
    />
  );
};

const PreviewWrap = styled.div`
  position: relative;
  outline: 1px solid black;

  img {
    background: var(--theme-bg);
  }

  img.loaded {
    background: repeating-conic-gradient(#808080 0% 25%, #dedede 0% 50%) 50% / 20px 20px;
  }

  .spinnerWrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000007a;
    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .progress {
    background-color: gray;
    border-radius: 2px;
    padding: 2px;
    width: 90%;
  }

  .hoverActions {
    position: absolute;
    bottom: 10px;
    right: 10px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover .hoverActions {
    opacity: 1;
  }

  .hoverActions > .MuiIconButton-root {
    background-color: var(--theme-bg);
    box-shadow: 0 1px 12px #00000033;
  }

  .hoverActions > .MuiIconButton-root:hover {
    background-color: var(--theme-panel);
  }
`;

const InfoTagArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
`;

const InfoTagWrap = styled.div`
  display: flex;
  background-color: #e5e5e54d;
  flex-direction: column;
  border-radius: 8px;
  padding: 8px;
  text-align: center;

  .title {
    opacity: 0.8;
    font-size: 0.8em;
    margin-bottom: 10px;
  }

  .value {
    font-weight: bold;
  }
`;

const InfoTag = ({ title, children, ...props }) => (
  <InfoTagWrap {...props}>
    <span className="title">{title}</span>
    <span className="value">{children}</span>
  </InfoTagWrap>
);

const Preview = (
  { entry, progress }:
  { entry: Asset, progress: number },
) => {
  const handleLoaded = (width, height) => {
    entry.currentMetaData.width = width;
    entry.currentMetaData.height = height;
  };

  return (
    <PreviewWrap>
      {progress !== null && (
        <div className="spinnerWrap">
          <LinearProgress
            className="progress"
            variant="determinate"
            value={progress}
          />
        </div>
      )}
      <RatioBox className="ratioBox" ratio={entry.aspectRatio || '16:9'}>
        <PreviewElement
          entry={entry}
          onLoaded={handleLoaded}
        />
      </RatioBox>
      {entry.originalAsset === null && (
        <Stack direction="row" alignItems="center" spacing={1} className="hoverActions">
          <IconButton href={entry.preview} target="_blank"><OpenInNewOutlined /></IconButton>
          {/* Note for testers: This only works with the same origin */}
          <IconButton
            href={entry.preview}
            target="_blank"
            download={entry.content.file_name}
          >
            <DownloadOutlined />
          </IconButton>
        </Stack>
      )}
    </PreviewWrap>
  );
};

const useUpload = () => {
  const [progress, setProgress] = React.useState(null);

  const startUpload = async (nativeFile: File) => {
    setProgress(0);
    const { data: uploadInstructions } = await api.get(
      'assets/_uploadUrl',
      { params: { projectId: store.currentProject.id } },
    ).finally(() => setProgress(null));

    const { ted_upload_id, upload_url } = uploadInstructions;

    const { data } = await axios.put(upload_url, nativeFile, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      onUploadProgress({ loaded, total }: ProgressEvent) {
        if (total === 0) {
          return;
        }
        setProgress((loaded / total) * 100);
      },
    }).finally(() => {
      setProgress(null);
    });

    return {
      response: data,
      uploadId: ted_upload_id,
    };
  };

  return { progress, startUpload };
};

const AssetEntry = observer((
  { asset, onUploadComplete } : {
    asset: Asset,
    onUploadComplete: (ted_upload_id: string) => void,
  },
) => {
  const { startUpload, progress } = useUpload();

  const fileExt = asset.content.path.split('.').pop();
  const allowedType = ACCEPT_MAP[fileExt];

  const onReplace = async e => {
    const newFile = e.target.files[0];
    if (newFile.type !== allowedType) {
      toast.error(`File type "${newFile.type}" is not allowed`);
      return;
    }
    const objectURL = URL.createObjectURL(newFile);
    asset.storeOriginal();
    asset.setUploadPreview(objectURL);
    asset.currentMetaData.size = newFile.size;
    asset.currentMetaData.type = newFile.type;
    asset.cropped = false;
    try {
      const { uploadId } = await startUpload(newFile);
      onUploadComplete(uploadId);
    } catch (e) {
      asset.reset();
      toast.error(`Upload failed: ${e.message}. Please try again.`);
    }
    // reset the file input in any case
    (e.target as HTMLInputElement).value = null;
  };

  const resolutionCheck = () => {
    const current = asset.currentMetaData;
    const original = asset.originalAsset;
    if (original === null) return null;
    if (original.width === current.width && original.height === current.height) {
      return checkOk('New Image has the same resolution');
    }
    return checkWarn(`Original resolution was ${original.width}x${original.height}`);
  };

  const typeCheck = () => {
    const original = asset.originalAsset;
    if (original === null) return null;

    // we rely on input type filtering here
    return checkOk('Same file type');
  };

  const sizeCheck = () => {
    const current = asset.currentMetaData;
    const original = asset.originalAsset;
    if (original === null) return null;
    const diff = current.size - original.size;
    if (diff < 0) {
      return checkOk(`New Image is ${prettyBytes(Math.abs(diff))} smaller`);
    }

    // 15% wiggle room
    if (current.size / original.size <= 1.15) {
      return checkOk(`New Image is only ${prettyBytes(diff)} larger`);
    }

    return checkWarn(`New Image is ${prettyBytes(diff)} larger`);
  };

  const aspectRatioCheck = () => {
    const original = asset.originalAsset;
    if (original === null) return null;
    if (original.aspectRatio === asset.aspectRatio) {
      return checkOk('New Image has the same aspect ratio');
    }
    return checkWarn(`Original aspect ratio was ${original.aspectRatio}`);
  };

  const croppable = asset.currentMetaData.type === 'image/jpeg'
    || asset.currentMetaData.type === 'image/png';
  const needsCrop = asset.modified && !asset.cropped && croppable;

  return (
    <AssetWrap data-asset={asset.content.file_path}>
      {asset.modified && (
        <div title="Change pending" className="changeDot" />
      )}
      {needsCrop && (
        <CropDialog file={asset} />
      )}
      <div className="preview">
        <Preview entry={asset} progress={progress} />
      </div>
      <div className="basicFileDesc">
        <div className="actualDesc">
          <TitleArea>
            <h3 className="assetName">{asset.content.name}</h3>
            <div className="actions">
              {asset.originalAsset !== null && (
                <Tooltip title="Undo pending change">
                  <IconButton onClick={() => asset.reset()}>
                    <Undo />
                  </IconButton>
                </Tooltip>
              )}
              <label className="replaceAssetAction" htmlFor={`replace-asset-${asset.id}`}>
                <input
                  hidden
                  accept={allowedType}
                  id={`replace-asset-${asset.id}`}
                  name={`replace-asset-${asset.id}`}
                  type="file"
                  onChange={onReplace}
                />
                <Button variant="outlined" component="span">
                  Replace&nbsp;
                  {fileExt === 'mp4' ? 'video' : 'image'}
                </Button>
              </label>
            </div>
          </TitleArea>

          <InfoTagArea className="InfoTagArea">
            <Check check={typeCheck}>
              <InfoTag title="Type">{fileExt}</InfoTag>
            </Check>
            <Check check={sizeCheck}>
              <InfoTag title="Size">{`~ ${asset.humanSize}`}</InfoTag>
            </Check>
            <Check check={resolutionCheck}>
              <InfoTag title="Resolution">
                {asset.resolution}
              </InfoTag>
            </Check>
            <Check check={aspectRatioCheck}>
              <InfoTag title="Aspect Ratio">{asset.aspectRatio}</InfoTag>
            </Check>
            {/* <InfoTag title="Duration">120m</InfoTag>
            <InfoTag title="Frame Rate">60fps</InfoTag>
            <InfoTag title="Video encoding">
              h264 with&nbsp;<code>faststart</code>&nbsp;flag
            </InfoTag>
            <InfoTag title="Audio encoding">mp3</InfoTag> */}
          </InfoTagArea>
          {asset.content.note && (
            <NoteBox>
              {asset.content.note}
            </NoteBox>
          )}
        </div>
      </div>
    </AssetWrap>
  );
});

export default AssetEntry;
