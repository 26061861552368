import React from 'react';
import styled from '@emotion/styled';
import { WarningRounded } from '@mui/icons-material';

export const CenterMessage = styled.div`
  padding: 1.5em;
  text-align: center;
  font-size: 1.5em;

  .icon {
    max-width: 300px;
    font-size: 1.5em;
    margin-bottom: 20px;
    color: orange;
  }

  p {
    font-size: 0.8em;
    opacity: 0.9;
  }

  pre {
    font-size: 14px;
    text-align: left;
    max-width: 100%;
    max-width: 100%;
    display: inline-block;
  }
`;

export const FileError = ({ name, message, error = null }) => {
  return (
    <CenterMessage>
      <WarningRounded className="icon" />
      <div className="error-name">{name}</div>
      <p className="error-message">{message}</p>
      <pre>
        <code>{error}</code>
      </pre>
    </CenterMessage>
  );
};

