import { makeAutoObservable } from "mobx";
import Entry from "./modules/entry";
import { LanguageFile } from "./modules/files";

type AISuggestionEntryOpts = {
  key: string;
  value: string;
  originalEntry: Entry;
};

export class AISuggestionEntry {
  key = "";
  value = "";

  originalEntry: Entry = null;
  originalValue: string = null;

  applied = false;

  constructor(opts: AISuggestionEntryOpts) {
    this.key = opts.key;
    this.value = opts.value;
    this.originalEntry = opts.originalEntry;
    this.originalValue = opts.originalEntry.value;
    makeAutoObservable(this);
  }

  apply() {
    this.originalEntry.setValue(this.value);
    this.applied = true;
  }

  revert() {
    this.originalEntry.setValue(this.originalValue);
    this.applied = false;
  }

  setValue(value: string) {
    this.value = value;
  }
}

export class AIChatMessage {
  id = crypto.randomUUID();
  content: string;
  from: "user" | "ai";
  createdAt: Date;
  tokenCount?: number;

  constructor(opts: {
    content: string;
    from: "user" | "ai";
    createdAt?: Date;
  }) {
    this.content = opts.content;
    this.from = opts.from;
    this.createdAt = opts.createdAt || new Date();
    makeAutoObservable(this);
  }
}

//     {
//       text: "Hi, this is a test user message.",
//       from: "user",
//       id: crypto.randomUUID(),
//     },
//     {
//       text: [
//         `Look at <key>chapter.01_intro.section-40.copy</key>.`,
//         "",
//         "```json.translation",
//         '{"chapter.01_intro.section-40.copy": "testing"}',
//         "```",
//         "",
//         "Test",
//       ].join("\n"),
//       from: "ai",
//       id: crypto.randomUUID(),
//     },
//     {
//       text: `This file contains the content for **Chapter 2: Diversity and Inclusion** <key>collections._02_diversity_and_inclusion</key> of a learning resource.
// It explains the concepts of <key>chapter.02_diversity_and_inclusion.section-20.headline</key> **diversity** and <key>chapter.02_diversity_and_inclusion.section-60.headline</key> **inclusion** and the importance of both for creating a successful and productive environment.
// It uses examples and interactive elements to help the user understand these topics. For instance, it uses the iceberg metaphor <key>chapter.02_diversity_and_inclusion.section-40.slide[0].headline</key> to differentiate between visible and invisible characteristics.
// In summary, the file aims to teach the user about the importance of diversity and inclusion and how they are interconnected.`,
//       from: "ai",
//       id: crypto.randomUUID(),
//     },

export class AIChat {
  file: LanguageFile;
  messages: AIChatMessage[] = [];

  constructor(opts: { file: LanguageFile }) {
    this.file = opts.file;
    makeAutoObservable(this);
  }

  clear() {
    this.messages = [];
  }

  addMessage(message: {
    content: string;
    from: "user" | "ai";
    tokenCount?: number;
  }) {
    this.messages.push(new AIChatMessage(message));
  }
}

class AIChatStore {
  chats: AIChat[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  addChat(file: LanguageFile) {
    const chat = new AIChat({ file });
    this.chats.push(chat);
    return chat;
  }

  openChat(file: LanguageFile) {
    const chat = this.chats.find((c) => c.file === file);
    if (chat) return chat;
    return this.addChat(file);
  }
}

const aiChatStore = new AIChatStore();
export default aiChatStore;
