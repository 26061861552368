import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import api from './api-client';

interface ProjectEnvironment {
  created_at: string;
  external_url: string;
  id: string;
  name: string;
  slug: string;
  state: string;
  updated_at: string;
}

const useApiQuery = <T = unknown>(
  req: AxiosRequestConfig,
  opts?: UseQueryOptions<T>, // Not sure if this works, this is dark magic
) => {
  // we generate the query function based on the request config
  const queryFn = () => api.request(req).then(r => r.data as T);
  // and pass everything to use query
  // use the splitted url as the key
  return useQuery<T, Error>(req.url.split('/'), queryFn, opts);
};

export const useProjectEnvs = (projectId: string) => {
  const req = {
    url: `gitlab/projects/${projectId}/environments`,
    method: 'GET',
  } as AxiosRequestConfig;
  return useApiQuery<ProjectEnvironment[]>(req);
};

export const shutUpEslint = null;
