/* eslint-disable max-classes-per-file */
import { makeAutoObservable } from 'mobx';

class Store {
  openFolders = new Set();

  constructor() {
    makeAutoObservable(this);
  }

  setOpenFolders(folders: string[]) {
    this.openFolders = new Set(folders);
  }

  open(folder: string) {
    this.openFolders.add(folder);
  }

  close(folder: string) {
    this.openFolders.delete(folder);
  }

  toggle(folder: string) {
    if (this.openFolders.has(folder)) {
      this.close(folder);
    } else {
      this.open(folder);
    }
  }

  reset() {
    this.openFolders.clear();
  }
}

const store = new Store();
export default store;
