import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { GlobalHotKeys, configure } from 'react-hotkeys';
import { observer } from 'mobx-react';
import { IconButton } from '@mui/material';
import { Close, ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';

import { CaseToggle, RegexToggle, WholeWordToggle } from './sidebar/utils';
import store from '../store';

configure({
  ignoreTags: [],
});

const SearchWrap = styled.div`
  border: 1px solid #0000006e;
  position: fixed;
  top: 38px;
  right: 32px;
  width: 420px;
  padding: 3px;
  background-color: #393939;
  background-color: var(--theme-panel);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 35;

  display: flex;
  align-items: center;

  input {
    background-color: black;
    background-color: var(--theme-alt-panel);
    color: var(--theme-text);
    border: 0;
    width: 100%;
    padding: 10px 4px;
    border-radius: 4px;
    padding-right: 76px;

    &:focus {
      box-shadow: 0 0 0 1px #2196f3;
      outline: none;
    }
  }

  .inputOptions {
    margin-left: -102px;
    display: flex;
    align-items: center;

    & > * {
      padding: 4px;
    }
  }

  .count {
    text-wrap: no-wrap;
    white-space: nowrap;
    margin: 0 10px;
    min-width: 55px;
    opacity: 0.9;
  }

  .close {
    margin-left: 10px;
  }

  svg {
    color: inherit;
    fill: currentColor;
  }
`;

const SearchField = observer(({ onClose, inputRef }) => {
  const handleChange = e => {
    store.findReplace.find.value = e.target.value;
  };

  const findNextFrom = from => {
    for (let a = from; a < store.replaced.length; a++) {
      if (store.replaced[a].some(r => typeof r === 'object')) return a;
    }
    return null;
  };

  const findPrevFrom = from => {
    for (let a = from; a >= 0; a--) {
      if (store.replaced[a].some(r => typeof r === 'object')) return a;
    }
    return null;
  };

  const onFocusNext = () => {
    let found = findNextFrom(store.findReplace.focusedIndex + 1);
    if (found === null) found = findNextFrom(0);

    store.findReplace.focusedIndex = found;
  };

  const onFocusPrev = () => {
    let found = findPrevFrom(store.findReplace.focusedIndex - 1);
    if (found === null) found = findPrevFrom(store.replaced.length - 1);

    store.findReplace.focusedIndex = found;
  };

  const keyUpHandler = e => {
    if (e.key === 'Escape') onClose(e);
  };

  const noResults = store.findResultCount === 0;

  const countText = (() => {
    if (noResults) return '';
    if (store.relativeFindIndex === null) return `? / ${store.findResultCount}`;
    return `${store.relativeFindIndex + 1} / ${store.findResultCount}`;
  })();

  return (
    <SearchWrap>
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        onChange={handleChange}
        value={store.findReplace.find.value}
        type="text"
        placeholder="Find in this file"
        onKeyUp={keyUpHandler}
        ref={inputRef}
      />
      <div className="inputOptions">
        <CaseToggle
          className="case"
          tabIndex={-1}
          onChange={e => store.findReplace.find.caseSensitive = e.target.checked}
          checked={store.findReplace.find.caseSensitive}
        />
        <WholeWordToggle
          className="case"
          tabIndex={-1}
          onChange={e => store.findReplace.find.wholeWord = e.target.checked}
          checked={store.findReplace.find.wholeWord}
        />
        <RegexToggle
          className="case"
          tabIndex={-1}
          onChange={e => store.findReplace.regex = e.target.checked}
          checked={store.findReplace.regex}
        />
      </div>
      <div className="count">{countText}</div>
      <IconButton
        disabled={noResults}
        className="prev"
        size="small"
        onClick={onFocusPrev}
      >
        <ExpandLessRounded />
      </IconButton>
      <IconButton
        disabled={noResults}
        className="next"
        size="small"
        onClick={onFocusNext}
      >
        <ExpandMoreRounded />
      </IconButton>
      <IconButton
        size="small"
        onClick={onClose}
        className="close"
      >
        <Close />
      </IconButton>
    </SearchWrap>
  );
});

export default () => {
  const input = useRef<HTMLInputElement>();
  const [visible, setVisible] = useState(false);

  const keyMap = {
    search: ['ctrl+f', 'cmd+f'],
    close: 'esc',
  };

  const close = () => {
    store.findReplace.enableCount--;
    setVisible(false);
  };

  const handlers = {
    search(e) {
      e.preventDefault();
      setVisible(true);
      store.findReplace.enableCount++;
      if (input && input.current) input.current.focus();
    },
    close,
  };

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      {visible && (
        <SearchField inputRef={input} onClose={close} />
      )}
    </>
  );
};
