import { makeAutoObservable, reaction } from 'mobx';
import Fuse from 'fuse.js';

import api from '../api-client';
import Project from './modules/project';

const fuseOptions = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ['name', 'description', 'createdAt', 'lastActivityAt'],
};

class Projects {
  projects = [];
  loaded = false;

  groupFilter = [];
  textFilter = '';
  sorting = 'name:desc';

  constructor(projects = []) {
    this.projects = projects;
    this.groupFilter = JSON.parse(localStorage.getItem('ted.groupFilter')) || ['hydra'];
    makeAutoObservable(this);
  }

  saveGroupfilter = reaction(
    () => this.groupFilter,
    groups => localStorage.setItem('ted.groupFilter', JSON.stringify(groups)),
  );

  get favorites() {
    return this.projects.filter(project => project.isFavorite);
  }

  get groupFilteredProjects() {
    const [sort, order] = this.sorting.split(':');
    const sortFn = sort === 'name' ? 'toLowerCase' : 'getTime';

    const dir = {
      asc: 1,
      desc: -1,
    }[order];

    return this.projects
      .filter(item => this.groupFilter.some(group => item.group.startsWith(group)))
      .sort(({ [sort]: a }, { [sort]: b }) => {
        if (a[sortFn]() === b[sortFn]()) return 0;
        return (a[sortFn]() < b[sortFn]() ? 1 : -1) * dir;
      });
  }

  get fuse() {
    const fuzzy = new Fuse(this.groupFilteredProjects, fuseOptions);
    return fuzzy.search(this.textFilter);
  }

  get filteredProjects() {
    if (this.textFilter === '') {
      return this.groupFilteredProjects
        .filter(repo => repo.isFavorite)
        .concat(this.groupFilteredProjects.filter(repo => !repo.isFavorite));
    }

    return this.fuse
      .filter(repo => repo.isFavorite)
      .concat(this.fuse.filter(repo => !repo.isFavorite));
  }

  get escapedTextFilter() {
    return this.textFilter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  get hasProjects() {
    return this.projects.length > 0;
  }

  setTextFilter(value) {
    this.textFilter = value;
  }

  clear() {
    this.projects = [];
  }

  async fetchProjects() {
    const favorites = JSON.parse(window.localStorage.getItem('ted.favorites')) || [];

    const repos = await api.get('projects')
      .then(r => r.data)
      .then(repos => repos.map(r => ({
        ...r,
        ...{
          group: r.group.full_path,
          isFavorite: favorites.includes(r.id),
        },
      })));

    // eslint-disable-next-line require-atomic-updates
    this.projects = repos
      .filter(r => r.details.hasLangconfig || r.details.hasLangfiles)
      .map(repo => new Project(repo));

    this.loaded = true;
  }
}

export default new Projects();
