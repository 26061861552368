import { makeAutoObservable } from 'mobx';

class Store {
  find = { value: '', caseSensitive: false, wholeWord: false };
  replace = { value: '' };
  regex = false;
  focusedIndex = 0;
  enableCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  get enabled() {
    return this.enableCount > 0;
  }

  get findRegex() {
    if (this.find.value === '') {
      return null;
    }

    const regexp = (() => {
      const { find, regex } = this;
      const flags = find.caseSensitive ? 'g' : 'gi';

      let cleanFind = find.value;

      if (!regex) {
        cleanFind = cleanFind.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
      }

      if (find.wholeWord) {
        cleanFind = `\\b${cleanFind}\\b`;
      }

      try {
        const regexp = new RegExp(cleanFind, flags);
        return regexp;
      } catch (e) {
        return e;
      }
    })();

    if (regexp instanceof Error) {
      console.warn('invalid find regex');
      return null;
    }
    return regexp;
  }

  get replaceRegex() {
    const regexp = this.findRegex;
    if (!regexp) return null;
    return new RegExp(regexp.source, regexp.flags);
  }

  preview(entries) {
    if (this.find.value === '') {
      return [];
    }
    const { findRegex, replaceRegex, replace } = this;

    if (findRegex === null) return [];
    if (findRegex instanceof Error) return findRegex;

    if (!entries) {
      return new Error('You need to open a file first');
    }

    try {
      return entries.map(entry => {
        const text = entry.value;
        const chunks = [];
        if (findRegex === null) return text;
        let matches = findRegex.exec(text);

        let c = 0;
        let index = 0;
        while (matches !== null) {
          // non match
          if (matches.index > index) {
            chunks.push(text.slice(index, matches.index));
          }
          const replacement = matches[0].replace(replaceRegex, replace.value);
          chunks.push({ match: matches[0], replacement: replacement.toString() });
          index = matches.index + matches[0].length;

          matches = findRegex.exec(text);
          c += 1;
          if (c > 200) throw new Error('Regex overflow. Over 200 results. Aborting');
        }

        if (index !== text.length) chunks.push(text.slice(index, text.length));
        return chunks;
      });
    } catch (e) {
      return e;
    }
  }

  reset() {
    this.find = { value: '', caseSensitive: false, wholeWord: false };
    this.replace = { value: '' };
    this.regex = false;
    this.focusedIndex = 0;
    this.enableCount = 0;
  }
}

const store = new Store();
export default store;

