import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

import {
  ButtonBase,
  Popover,
} from '@mui/material';

import store from '~/store';
import ProjectBox from './ProjectBox';

const BreadWrap = styled.div`
  position: relative;
  margin-right: 16px;
  &::after {
    content: "keyboard_arrow_right";
    font-family: 'Material Icons';
    position: absolute;
    right: -18px;
    top: 0;
    line-height: 40px;
    font-size: 1.5em;
    height: 100%;
  }
  &:last-of-type { margin-right: 0; }
  &:last-of-type::after { content: ""; }
`;

const BreadButton = styled(ButtonBase)`
  padding: 10px 12px;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 2px;
  &:hover { background-color: rgba(255, 255, 255, 0.10); }
` as unknown as typeof ButtonBase;

const ProjectMenu = observer(({ disabled }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const project = store.currentProject;
  if (!project) return null;
  return (
    <BreadWrap>
      <BreadButton
        aria-owns={anchorEl ? 'project-dialog' : null}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
        color="primary"
      >
        <span>{project.name}</span>
      </BreadButton>
      <Popover
        id="project-dialog"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        container={document.getElementById('popovers')}
      >
        <ProjectBox project={project} />
      </Popover>
    </BreadWrap>
  );
});

export default ProjectMenu;
