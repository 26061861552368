import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  text-align: center;
  font-family: sans-serif;
`;

const Title = styled.h2`
  font-size: 8em;
  color: #7b7b7b;
`;

const Nothing = () => (
  <Container>
    <Title>404</Title>
    <h3>There is nothing here</h3>
  </Container>
);

export default Nothing;
