import React from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";

import { ButtonBase } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { motion, AnimatePresence, MotionConfig, Reorder } from "framer-motion";

import ui, { AISuggestionTab } from "~/store/tabs";
import sidebar from "~/store/sidebar";
import FileLink from "../../components/FileLink";
import { BotIcon } from "lucide-react";

const TabContainer = styled.div`
  position: relative;
  z-index: 20;
  padding-left: 10px;
  display: flex;
  width: 100%;
  font-size: 14px;
  padding-top: 6px;
  height: 44px;
  min-height: 44px;
  max-height: 44px;
  overflow: hidden;

  .contentWrap {
    flex: 1;
    display: flex;
    width: 100%;
    padding-right: 15px;
  }
`;

const Tab = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;

  background-color: transparent;
  max-width: 250px;
  min-width: 60px;
  height: 100%;
  padding-right: 3px;
  padding: 0 15px;
  position: relative;
  z-index: 2;

  .main {
    padding: 10px 5px;
    padding-left: 10px;
    flex: 1;

    max-width: 137px;
    overflow: hidden;
    line-height: 1;
  }

  &:hover {
    background: var(--theme-alt-panel);
    --background: var(--theme-alt-panel);
    z-index: 0;

    .close {
      opacity: 1;
    }
  }

  .close {
    height: 22px;
    width: 22px;
    margin-bottom: 2px;
    border-radius: 100%;
    font-size: 16px;
    svg {
      font-size: inherit;
    }
    opacity: 0;
  }

  .close:hover {
    background-color: var(--theme-bg);
  }

  --background: transparent;
  --corner-radius: 10 10 -10 -10;

  &[data-active] {
    .close {
      opacity: 1;
    }
    background: var(--theme-panel);
    --background: var(--theme-panel);
    filter: drop-shadow(0 -1px 1px var(--theme-box-shadow-light));
    z-index: 3;
  }

  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  margin-right: -10px;
  @supports (background: paint(something)) {
    background: paint(inverted-corners) !important;
    margin-right: -25px;
  }
`;

const FileTabContent = observer(({ tab, onClose }) => {
  return (
    <>
      <FileLink
        className="main truncate"
        customTitle={tab.title}
        file={tab.file}
        short
      />
      <ButtonBase className="close" size="small" onClick={onClose}>
        <CloseIcon />
      </ButtonBase>
    </>
  );
});

const TabElement = ({ tab, active, onClick, onClose }) => {
  // const ref = useMeasurePosition(onUpdatePosition);
  // middle button click
  const handleAuxClick = (e) => {
    if (e.button !== 1) return;
    e.preventDefault();
    onClose();
  };

  // only left button click
  const handleClick = (e) => {
    if (e.button !== 0) return;
    e.preventDefault();
    onClick(e);
  };

  const content =
    tab instanceof AISuggestionTab ? (
      <div className="flex px-1 gap-1 items-center">
        <BotIcon className="text-gray-500 w-4 h-4" />
        <div className="px-1 truncate max-w-32">{tab.label}</div>
        <ButtonBase className="close" size="small" onClick={onClose}>
          <CloseIcon />
        </ButtonBase>
      </div>
    ) : (
      <FileTabContent tab={tab} onClose={onClose} />
    );

  return (
    <motion.div
      className="min-w-2 h-full"
      layout
      initial={{ x: -10, opacity: 0, scale: 0.9 }}
      animate={{ x: 0, opacity: 1, scale: 1, transition: { duration: 0.1 } }}
      exit={{ x: -1, opacity: 0, scale: 0.9, transition: { duration: 0.1 } }}
    >
      <Tab
        className="tab"
        data-active={active ? true : undefined}
        data-tab-file={tab.file.name}
        onAuxClick={handleAuxClick}
        onMouseDown={handleClick}
      >
        {content}
      </Tab>
    </motion.div>
  );
};

export default observer(() => {
  const handleMove = (tabs) => {
    ui.tabs = tabs;
  };

  const setActive = (tab) => {
    ui.activeTab = tab;
  };

  const tabs = ui.tabs.filter((t) => t);

  return (
    <MotionConfig
      transition={{
        type: "spring",
        duration: 0.25,
        bounce: 0.3,
        ease: "easeInOut",
      }}
    >
      <TabContainer
        style={{
          maxWidth: sidebar.isOpen
            ? "calc(100vw - 400px)"
            : "calc(100vw - 100px)",
        }}
      >
        <AnimatePresence>
          <Reorder.Group
            axis="x"
            values={tabs}
            onReorder={handleMove}
            className="contentWrap"
          >
            {tabs.map((tab) => {
              return (
                <Reorder.Item key={tab.id} value={tab}>
                  <TabElement
                    key={tab.id}
                    tab={tab}
                    active={tab === ui.activeTab}
                    onClick={() => setActive(tab)}
                    onClose={() => ui.removeTab(tab)}
                  />
                </Reorder.Item>
              );
            })}
          </Reorder.Group>
        </AnimatePresence>
      </TabContainer>
    </MotionConfig>
  );
});
