import { LanguageFile } from "~/store/modules/files";
import MarkdownIt from "markdown-it";

const md = new MarkdownIt({
  html: true,
});

const textLike = [".text", ".head", ".copy", ".start", ".end", ".feedback"];

// listMatch created a regex based on the given value
// for ".value[x]" where [x] can be optional
function listMatch(match: string) {
  const regex = new RegExp(`.${match}(?:\\[(\\d+)\\])?`);
  return regex;
}

function endsWithList(value: string, match: string) {
  return listMatch(match).test(value);
}

// valueToMarkdown takes a string and returns a 'markdownified' string.
// it has a few rules depending on the keyvalue.
// For example keys ending in '.title' are treated as headings.
function entryToMarkdown(entry: LanguageFile["entries"][0]) {
  const { id } = entry;
  if (id.endsWith(".title") || id.endsWith(".headline")) {
    return `## ${entry.value}`;
  }
  if (id.endsWith(".subtitle")) {
    return `### ${entry.value}`;
  }

  if (endsWithList(id, ".list")) {
    return `- ${entry.value}`;
  }

  if (textLike.some((text) => id.endsWith(text))) {
    return entry.value;
  }

  if (textLike.some((text) => endsWithList(id, text))) {
    return `- ${entry.value}`;
  }

  return `<s>${entry.value}</s>`;
}

export function entriesToMarkdown(entries: LanguageFile["entries"]) {
  const doc = [];
  entries.forEach((entry) => {
    doc.push(entryToMarkdown(entry));
  });
  return doc.join("\n\n");
}

export function markdownToHtml(markdown: string) {
  return md.render(markdown);
}

export function entriesToHtml(entries: LanguageFile["entries"]) {
  const markdown = entriesToMarkdown(entries);
  const content = markdownToHtml(markdown);
  return content;
}
