/* eslint-disable max-classes-per-file */
import { makeAutoObservable } from 'mobx';

class Store {
  activeItem = 'browser';
  commit = {
    summary: '',
    description: '',
  };

  constructor() {
    makeAutoObservable(this);
  }

  get isOpen() {
    return this.activeItem !== null;
  }

  setActiveItem = item => {
    this.activeItem = item;
  };

  setCommit(commit) {
    this.commit = commit;
  }

  setCommitSummary(summary) {
    this.commit.summary = summary;
  }

  setCommitDescription(description) {
    this.commit.description = description;
  }

  reset() {
    this.activeItem = 'browser';
    this.commit = {
      summary: '',
      description: '',
    };
  }
}

const store = new Store();
export default store;
