import { Workbox } from 'workbox-window';
import store from './store';

const wb = new Workbox('/service-worker.js');


// Check that service workers are supported
const isLocalhost = window.location.hostname === 'localhost';
if (!isLocalhost && window.location.pathname === '/' && 'serviceWorker' in navigator) {
  const showSkipWaitingPrompt = () => {
    store.swUpdatePrompt = () => {
      wb.addEventListener('controlling', () => {
        window.location.reload();
      });

      wb.messageSkipWaiting();
    };
  };

  // Add an event listener to detect when the registered
  // service worker has installed but is waiting to activate.
  wb.addEventListener('waiting', showSkipWaitingPrompt);

  wb.register();
}
