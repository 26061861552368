import styled from '@emotion/styled';
import { Button, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { observer } from 'mobx-react';
import React from 'react';
import upgradeRocket from '../assets/undraw_upgrade_06a0.svg';
import store from '../store';

const SwUpdateWrap = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;

  background-color: var(--theme-panel);
  box-shadow: 0 0 10px rgb(0 0 0 / 13%);
  border-radius: 5px;
  padding: 15px;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  h3 {
    margin: 0;
  }

  .close {
    margin-left: 10px;
    padding: 2px;
  }

  .content {
    display: flex;
    margin-bottom: 15px;

    p {
      flex: 1;
    }

    img {
      max-width: 135px;
      min-width: 50px;
      margin-right: 20px;
    }
  }

  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    & > * {
      margin-right: 5px;
    }
  }
`;

export default observer(() => {
  const [updating, setUpdating] = React.useState(false);

  if (!store.swUpdatePrompt) return null;

  const handleClick = () => {
    setUpdating(true);
    store.swUpdatePrompt();
  };

  const handleCancel = () => {
    store.swUpdatePrompt = null;
  };

  return (
    <SwUpdateWrap>
      <div className="head">
        <h3>New version available</h3>
        <IconButton
          className="close"
          onClick={() => store.swUpdatePrompt = null}
          size="large"
        >
          <Close />
        </IconButton>
      </div>
      <div className="content">
        <img src={upgradeRocket} alt="" />
        <p>
          A new version of TED is available. 🎉
          <br />
          <br />
          <div className="actions">
            <Button
              disabled={updating}
              color="secondary"
              variant="outlined"
              onClick={handleClick}
            >
              Update now
            </Button>
            <Button
              disabled={updating}
              variant="outlined"
              onClick={handleCancel}
            >
              Do this later
            </Button>
          </div>
        </p>
      </div>
    </SwUpdateWrap>
  );
});
