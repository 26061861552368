import React from "react";
import EditArea from "./edit-area";
import QuickSearch from "./QuickSearch";
import Left from "./sidebar";
import StatusBar from "./StatusBar";
import EditTabs from "./tabs";
import Actions from "./Actions";

export default () => {
  return (
    <div className="grid grid-cols-[min-content_1fr] h-full">
      <QuickSearch />
      <div className="min-h-[calc(100vh-42px)] flex flex-col z-40 bg-inherit bg-theme rounded sticky top-0 left-0">
        <Left />
      </div>
      <main className="h-[calc(100vh-32px)] mx-1">
        <EditTabs />
        <EditArea className="flex-1" />
      </main>
      <StatusBar />
      <Actions />
    </div>
  );
};
