/** @jsx jsx */
import React from 'react';
import { observer } from 'mobx-react';

import { FormControlLabel, Button, TextField, Checkbox } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import Select from 'react-select';
import store from '~/store';

import { AssetFile } from '~/store/modules/files';
import { SidebarBox, SingleOption, CaseToggle, WholeWordToggle } from './utils';

const ActionArea = withTheme(styled.div`
  margin-top: 35px;
  border-top: 1px solid ${p => p.theme.palette.divider};
  padding: 12px;

  p {
    color: gray;
    margin-bottom: 20px;
    margin-top: 4px;
    padding-left: 2px;
    font-size: 14px;
  }
`);

const selectStyles = {
  container: base => css`
    ${base};
  `,
  indicatorSeparator: () => css`
    display: none;
  `,
  valueContainer: base => css`
    ${base};
    padding-left: 15px;
    min-height: 40px;
  `,
  menu: base => css`
    ${base};
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  `,
  menuList: base => css`
    ${base};
    padding: 8px 0;
  `,
  option: (base, state) => css`
    ${base};
    padding: 12px 16px;
    line-height: 1.5em;
    ${state.isFocused && 'background-color: rgba(0, 0, 0, 0.12); color: inherit;'}
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.14);
      box-shadow: 0 none;
    }
    &:focus {
      background-color: rgba(0, 0, 0, 0.05);
    }
  `,
};


@withTheme
@observer
class FindReplace extends React.Component {
  valueInput = React.createRef();
  replaceWithInput = React.createRef();

  componentDidMount() {
    store.findReplace.enableCount++;
  }

  componentWillUnmount() {
    store.findReplace.enableCount--;
  }

  doTheReplace = () => {
    store.applyFindReplace();
  };

  chooseRegexPreset = v => {
    store.findReplace.find.value = v.value.find;
    store.findReplace.replace.value = v.value.replace;
  };

  render() {
    const options = [
      { value: { find: '\\B"([^"]+)"', replace: '„$1“' }, label: 'Quote Replacement → DE' },
      { value: { find: '\\B"([^"]+)"', replace: '“$1”' }, label: 'Quote Replacement → EN' },
      { value: { find: '\\s+$', replace: '' }, label: 'Remove trailing whitespace' },
    ];

    const selStyles = {
      ...selectStyles,
      menu: base => css`
        ${base};
        background-color: ${this.props.theme.palette.background.paper};
        color: ${this.props.theme.palette.text.primary};
      `,
      control: base => css`
        ${base};
        background-color: ${this.props.theme.palette.background.paper};
        border-color: rgba(0, 0, 0, 0.14);
      `,
      singleValue: base => css`
        ${base};
        color: ${this.props.theme.palette.text.primary};
      `,
    };

    const isAssetFile = store.currentFile instanceof AssetFile;

    return (
      <SidebarBox title="Find & Replace">
        <FormControlLabel
          style={{ margin: '0 3px' }}
          control={(
            <Checkbox
              checked={store.findReplace.regex}
              onChange={e => store.findReplace.regex = e.target.checked}
            />
          )}
          label="Enable regex"
        />

        {store.findReplace.regex && (
          <div style={{ margin: '12px', marginBottom: '20px' }}>
            <Select
              styles={selStyles}
              onChange={this.chooseRegexPreset}
              placeholder="Regex Presets"
              options={options}
            />
          </div>
        )}

        <SingleOption>
          <TextField
            value={store.findReplace.find.value}
            fullWidth
            placeholder="value to find"
            onChange={e => store.findReplace.find.value = e.target.value}
            helperText={store.replaced instanceof Error ? store.replaced.toString() : null}
            error={store.replaced instanceof Error}
          />
          <WholeWordToggle
            tabIndex={-1}
            onChange={e => store.findReplace.find.wholeWord = e.target.checked}
            checked={store.findReplace.find.wholeWord}
          />
          <CaseToggle
            tabIndex={-1}
            onChange={e => store.findReplace.find.caseSensitive = e.target.checked}
            checked={store.findReplace.find.caseSensitive}
          />
        </SingleOption>
        <SingleOption style={{ margin: '20px 0' }}>
          <TextField
            value={store.findReplace.replace.value}
            fullWidth
            placeholder="replace with"
            onChange={e => store.findReplace.replace.value = e.target.value}
            style={{ marginRight: '12px' }}
          />
        </SingleOption>
        <ActionArea>
          {/* <p>
            {'Going to replace '}
            <strong>{toReplace}</strong>
            {' entries.'}
          </p> */}
          {isAssetFile && (
            <p>You can not use find &amp; replace in an asset file.</p>
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={this.doTheReplace}
            disabled={isAssetFile}
          >
            Replace all
          </Button>
        </ActionArea>
      </SidebarBox>
    );
  }
}

FindReplace.propTypes = {};

export default FindReplace;
