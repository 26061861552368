import React from 'react';
import { themes, themeAsCssProps } from '../store/theme';

export default ({ theme = 'dark', ...rest }) => {
  const t = themes[theme];
  const cssProps = themeAsCssProps(t);
  return (
    <div style={cssProps} {...rest} />
  );
};
