const camelCaseToDashCase = str => {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase();
};

export const themeAsCssProps = theme => {
  const cssProps = { color: '#fff' };
  Object.keys(theme).forEach(key => {
    if (key.startsWith('_')) return;
    cssProps[`--theme-${camelCaseToDashCase(key)}`] = theme[key];
  });
  cssProps.color = theme.text;

  return cssProps;
};

const dark = {
  _base: 'dark',
  bg: '#222222',
  text: '#fff',
  lightText: '#ababab',
  panel: '#393a3b',
  altPanel: '#1b1b1b',
  linkText: '#b7f5ff',
  linkBg: '#2084d466',
  primary: '#03a9f4',
  success: '#8bc34a',
  warn: '#ffc107',
  danger: '#ff5722',
  highlight: '#5d513f',
  boxShadow: '#0c0c0cd4',
  boxShadowLight: '#0c0c0c3d',
};

const light = {
  _base: 'light',
  bg: '#efefef',
  text: '#000',
  lightText: '#313131',
  panel: '#fff',
  altPanel: '#e5e5e5',
  linkText: '#003e5a',
  linkBg: '#2084d466',
  primary: '#03a9f4',
  success: '#8bc34a',
  warn: '#ff7d00',
  danger: '#f44336',
  highlight: '#ffe47c',
  boxShadow: '#18181859',
  boxShadowLight: '#b6b6b63b',
};

const hydra = {
  ...dark,
  _base: 'dark',
  linkText: '#10ff00',
  linkBg: '#00791366',
  primary: '#10ff00',
};


export const themes = { light, dark, hydra };
