import { makeAutoObservable, autorun } from 'mobx';
import { themes, themeAsCssProps } from './theme';

const FONTS = {
  monospace: '\'Source Code Pro\', monospace',
  serif: 'Times, \'Times New Roman\', Georgia, serif',
  'sans-serif': '-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, Oxygen,'
    + ' Ubuntu, Cantarell, \'Open Sans\', \'Helvetica Neue\', sans-serif',
  fantasy: 'fantasy',
};

const selectFont = selected => FONTS[selected];

class UserSettings {
  settings = {
    displayFont: 'monospace',
    useDisplayFont: true,
    editFont: 'monospace',
    previewOpen: true,
    darkMode: false,
    theme: 'system',
    entryWidth: 620,
    entryFontSize: 18,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setSetting(name, value) {
    this.settings[name] = value;
  }

  toggleSetting(name) {
    this.settings[name] = !this.settings[name];
  }

  patchSettings(newSettings) {
    this.settings = { ...this.settings, ...newSettings };
  }

  get theme() {
    if (this.settings.theme === 'system') {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';
    }
    return this.settings.theme || 'light';
  }

  get themeVariant() {
    return this.themeProps._base;
  }

  get themeProps() {
    return themes[this.theme];
  }

  get displayFont() {
    return selectFont(this.settings.displayFont);
  }

  get editFont() {
    if (this.settings.useDisplayFont) return this.displayFont;
    return selectFont(this.settings.editFont);
  }
}

const store = new UserSettings();
export default store;

autorun(() => {
  const { theme } = store;
  const rs = window.document.body.style;

  const active = themes[theme] || themes.light;

  const cssProps = themeAsCssProps(active);
  Object.keys(cssProps).forEach(key => {
    rs.setProperty(key, cssProps[key]);
  });
});
