import { ButtonBase, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { GroupOutlined } from "@mui/icons-material";
import distanceInWordsToNow from "date-fns/formatDistanceToNow";

import { Favatar, Favorite } from "../components/ProjectUtils";
import Highlight from "../components/LegacyHighlight";

import store from "../store";

const formatDate = (date) => distanceInWordsToNow(date, { addSuffix: true });

/**
 * Styled existing components
 */
const StyledButtonBase = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  width: 100%;
` as unknown as typeof ButtonBase;

/**
 * Styled (wrapping) components
 */
const ItemWrap = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--theme-panel);
  transition: background 0.1s ease-in-out;
  color: inherit;
  &:hover {
    background-color: var(--theme-alt-panel);
  }

  .favorite {
    margin-right: 0.5em;
  }

  .muiIcon-root {
    color: inherit;
  }
`;

const ItemHeader = styled.header`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: inherit;
  padding: 16px 8px 16px 16px;
`;

const ItemHeadlines = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 1em;
  p {
    margin: 0;
  }
  small {
    margin-top: 0.5em;
  }
  .hl {
    background-color: #757de8;
    padding: 2px;
  }
`;

const ItemDetailes = styled.section`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  font-size: 0.9em;
  padding: 16px 8px 16px 16px;
  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span {
      margin-left: 0.5em;
    }
    .material-icons {
      color: rgba(0, 0, 0, 0.54);
    }
  }
`;

export const LoadingPlaceholder = () => {
  const randWidth = Math.floor(Math.random() * 100) + 50;
  return (
    <ItemWrap style={{ display: "flex" }}>
      <div style={{ margin: "9px 0" }}>
        <ItemHeader>
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            style={{ marginRight: 20 }}
          />
          <Skeleton variant="rectangular" width={randWidth} height={9} />
        </ItemHeader>
      </div>
    </ItemWrap>
  );
};

/**
 * Displays the information of a repo
 */
const RepoListItem = observer(({ repo, ...rest }) => {
  return (
    <ItemWrap {...rest}>
      <StyledButtonBase component={Link} to={repo.localHref} focusRipple>
        <ItemHeader>
          <Favatar src={repo.avatarUrl} fallback={repo.name} />
          <ItemHeadlines>
            <Highlight
              escaped
              text={repo.name}
              search={store.projects.escapedTextFilter}
              WrapperComponent="p"
            />
            {repo.description && (
              <Highlight
                escaped
                text={repo.description}
                search={store.projects.escapedTextFilter}
                WrapperComponent="small"
              />
            )}
          </ItemHeadlines>
        </ItemHeader>
        <ItemDetailes>
          {/* <span>
            {`Created: ${formatDate(repo.createdAt)}`}
          </span> */}
          <span className="">
            <span className="opacity-70">Last activity </span>
            <span>{formatDate(repo.lastActivityAt)}</span>
          </span>
          <div>
            <GroupOutlined />
            <span>{repo.group}</span>
          </div>
        </ItemDetailes>
      </StyledButtonBase>
      <Favorite
        className="favorite"
        toggleFavorite={() => repo.toggleFavorite(repo)}
        isFavorite={repo.isFavorite}
      />
    </ItemWrap>
  );
});

export default RepoListItem;
