/* eslint-disable max-len */
export default (entry, store) => `${`

### Problembeschreibung



#### Originaltext
\`\`\`
${entry.value}
\`\`\`


### Referenzen

* [Link zu TED](https://ted.hydra-newmedia.cloud/#/repo/${store.currentProject.id}/${store.currentFile.path}?entry=${entry.id})

/label ~Text

`.trim()}\n`;
