import { makeAutoObservable } from 'mobx';
import { lintText } from '../utils/text-linter';

export default class Entry {
  _key = Math.floor(Math.random() * 1000000000).toString(16);
  imported = false;
  id = '';
  value = '';

  meta = {
    marked: false,
    color: null,
  };

  editing = false;

  toBeCreated = false;
  toBeRemoved = false;
  remoteUpdated = false;
  conflictsWith = null;
  original = {
    id: null,
    value: null,
    index: null,
  };

  // TODO: probably unused, check
  parentFile = null;

  constructor(id, value?) {
    if (value === undefined && id instanceof Object) {
      Object.assign(this, id);
    } else {
      this.id = id;
      this.value = value;
    }
    makeAutoObservable(this, {
      _key: false,
      imported: false,
    });
  }

  get linterInfos() {
    return lintText(this.value);
  }

  get creationState() {
    return this.id === "" && this.toBeCreated === true;
  }

  get modifiedId() {
    return this.original.id !== null;
  }

  get modifiedValue() {
    return this.original.value !== null;
  }

  get modifiedIndex() {
    return this.original.index !== null;
  }

  get modified() {
    return (
      this.modifiedId || this.modifiedValue || this.toBeRemoved || this.toBeCreated
    );
  }

  findReplace(find, replace, opts = { caseSensitive: false }) {
    find = find.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    find = new RegExp(find, opts.caseSensitive ? 'g' : 'ig');
    this.setValue(this.value.replace(find, replace));
  }

  setEditing(value) {
    this.editing = value;
  }

  setValue(value = "") {
    if (value === this.value) return; // no update needed

    // value is back to original value
    if (this.original.value === value) this.original.value = null;
    // save the original value if this is the first edit
    else if (this.original.value === null) this.original.value = this.value;

    this.value = value;
    this.conflictsWith = null;
  }

  remoteUpdate(newEntry: Entry) {
    const newValue = newEntry.value;

    // nothing changes
    if (this.value === newValue) return this;
    if (this.original.value === newValue) return this; // just local changes
    // there is a remote update and also modified it (conflict)
    if (this.modifiedValue === true || this.toBeCreated || this.toBeRemoved) {
      this.conflictsWith = new Entry(newEntry.id, newEntry.value);
    } else {
      // just apply the remote update
      this.value = newValue;
      this.remoteUpdated = true;
    }
    return this;
  }

  resolveConflict() {
    this.conflictsWith = null;
  }

  setId(id = '') {
    if (id === this.id) return; // unneeded update

    // id is back to original id
    if (this.original.id === id) this.original.id = null;
    // save the original id if this is the first edit
    else if (this.original.id === null) this.original.id = this.id;

    this.id = id;
  }

  revertId() {
    if (this.original.id === null) return;
    this.id = this.original.id;
    this.original.id = null;
  }

  revertValue() {
    if (this.original.value === null) return;
    this.value = this.original.value;
    this.original.value = null;
  }

  revertEverything() {
    this.revertId();
    this.revertValue();
    this.unmarkRemoved();
  }

  markRemoved() {
    this.toBeRemoved = true;
  }

  unmarkRemoved() {
    this.toBeRemoved = false;
  }

  markSaved() {
    this.original.value = null;
    this.original.id = null;
    this.original.index = null;
    this.toBeCreated = false;
    this.remoteUpdated = false;
  }

  mark() {
    this.meta.marked = true;
  }
  unmark() {
    this.meta.marked = false;
  }
  toggleMark() {
    this.meta.marked = !this.meta.marked;
  }
}
