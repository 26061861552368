import React from "react";
import { observer } from "mobx-react";

import { FileTab } from "~/store/tabs";
import { Undo } from "@mui/icons-material";

import { LanguageFile } from "~/store/modules/files";
import { AlertCircleIcon } from "lucide-react";
import { DiffView, EditOnClick } from "~/components/DiffView";
import store from "~/store";

export const DiffViewRenderer = observer(
  ({ tab }: { tab: FileTab; scrollTop: number }) => {
    if (tab.file instanceof LanguageFile === false) {
      return (
        <div className="flex bg-gray-400/20 dark:bg-gray-800/20 rounded-lg flex-col gap-4 items-center justify-center p-8">
          <AlertCircleIcon className="h-12 w-12 opacity-50" />
          <p className="opacity-80">Can only show text file differences.</p>
        </div>
      );
    }

    const file = tab.file as LanguageFile;

    return (
      <div className="overflow-y-auto h-[calc(100vh-73px)]">
        <div className="shadow z-20 rounded-lg flex sticky top-0 justify-between items-center gap-2 border-b border-gray-200 bg-white dark:bg-zinc-950 dark:border-zinc-600 backdrop-blur-md">
          <h2 className="p-2 font-semibold inline-flex m-2">Difference</h2>
          <span>{tab.file.name}</span>
          <div>
            <button
              className="p-2 rounded-lg hover:bg-gray-400/20 dark:hover:bg-gray-400/40 mx-2 px-3 bg-gray-200/50 dark:bg-gray-200/30"
              onClick={() => store.openFile(file)}
            >
              Open File
            </button>
          </div>
        </div>
        <div className="">
          <table className="table-auto w-full">
            <thead>
              <tr className="text-gray-900 dark:text-zinc-100">
                <th className="px-3 py-3.5 text-left text-sm font-semibold w-1/5">
                  Key
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold w-2/5">
                  Before
                </th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold w-2/5">
                  Current
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {file.modifiedEntries.map((entry) => (
                <tr key={entry.id}>
                  <td className="relative py-4 pl-4 pr-3 text-sm border-t dark:border-zinc-700">
                    <DiffView
                      before={entry.original.id || entry.id}
                      after={entry.id}
                    />
                  </td>
                  <td className="relative py-4 pl-4 pr-3 text-sm border-t dark:border-zinc-700">
                    {entry.original.value || (
                      <span className="opacity-50">N/A</span>
                    )}
                  </td>
                  <td className="relative py-4 pl-4 pr-3 text-sm border-t dark:border-zinc-700">
                    <EditOnClick entry={entry}>
                      <DiffView
                        before={entry.original.value || entry.value}
                        after={entry.value}
                      />
                    </EditOnClick>
                  </td>
                  <td className="relative py-4 pl-4 pr-3 text-sm border-t dark:border-zinc-700">
                    <button
                      onClick={() => entry.revertEverything()}
                      className="hover:bg-gray-100 dark:hover:bg-gray-800 font-semibold p-2 px-3 rounded opacity-80 text-sm flex gap-1 items-center"
                    >
                      <Undo /> Revert
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
);
