import React, { useState } from "react";
import { diffWords, diffChars } from "diff";
import { Tooltip } from "@mui/material";

interface DiffChunk {
  count: number;
  added: boolean;
  removed: boolean;
  value: string;
  originalValue?: string;
}

interface GroupedDiffChunk extends DiffChunk {
  changed?: boolean;
}

function groupChanges(diff: DiffChunk[]): GroupedDiffChunk[] {
  const result: GroupedDiffChunk[] = [];
  let i = 0;
  while (i < diff.length) {
    if (diff[i].removed && i + 1 < diff.length && diff[i + 1].added) {
      result.push({
        count: diff[i].count + diff[i + 1].count,
        added: false,
        removed: false,
        changed: true,
        value: diff[i + 1].value,
        originalValue: diff[i].value,
      });
      i += 2;
    } else {
      result.push({ ...diff[i] });
      i++;
    }
  }
  return result;
}

export function DiffView({ before = "", after = "" }) {
  const diff = diffWords(before, after);

  // modify to add changed part (removed followed by added)
  const groupedDiff = groupChanges(diff);

  return (
    <div className="whitespace-pre-wrap">
      {groupedDiff.map((part, index) => {
        if (part.changed) {
          // diff by chars to check if this is a small change
          const charDiff = diffChars(part.originalValue, part.value);
          let content = <span className="font-bold">{part.value}</span>;
          const lenDiff = Math.abs(
            part.originalValue.length - part.value.length
          );
          const hasSpace =
            part.value.includes(" ") || part.originalValue.includes(" ");

          if (
            lenDiff < 2 &&
            charDiff.length <= 4 &&
            charDiff.length > 1 &&
            part.value.length > 2 &&
            !hasSpace
          ) {
            content = (
              <>
                {charDiff
                  .filter((c) => !c.removed)
                  .map((char, index) => (
                    <span
                      key={index}
                      className={char.added ? "font-bold underline" : ""}
                    >
                      {char.value}
                    </span>
                  ))}
              </>
            );
          }
          return (
            <Tooltip title={part.originalValue}>
              <span
                key={index}
                className="bg-purple-200 text-purple-800 rounded"
              >
                {content}
              </span>
            </Tooltip>
          );
        }
        if (part.added) {
          return (
            <span
              key={index}
              className="bg-green-200 font-bold text-green-800 rounded"
            >
              {part.value}
            </span>
          );
        }
        if (part.removed) {
          return (
            <span
              key={index}
              className="bg-red-200 text-red-800 rounded line-through"
            >
              {part.value}
            </span>
          );
        }
        return <span key={index}>{part.value}</span>;
      })}
    </div>
  );
}

interface EntryLike {
  value: string;
  setValue(value: string): void;
}

export const EditOnClick = ({
  entry,
  children,
}: {
  entry: EntryLike;
  children: React.ReactNode;
}) => {
  const [editing, setEditing] = useState(false);
  const handleClick = () => {
    setEditing(true);
  };

  if (editing) {
    return (
      <div className="relative">
        <textarea
          value={entry.value}
          onChange={(e) => entry.setValue(e.target.value)}
          className="w-full rounded p-1 border bg-slate-50 dark:bg-slate-950 h-full bg-transparent outline-none"
          onBlur={() => setEditing(false)}
          autoFocus
        />
      </div>
    );
  }

  return (
    <div className="relative p-1" onClick={handleClick}>
      {children}
    </div>
  );
};
