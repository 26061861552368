import { GitlabFile } from '~/api-client';
import LanguageFile from './language-file';
import AssetFile from './asset-file';

export type File = LanguageFile | AssetFile;

export {
  LanguageFile,
  AssetFile,
};

export const fromGitlabFile = (file: GitlabFile): File => {
  if (file instanceof GitlabFile !== true) throw new Error('did not pass gitlab file');
  if (file.file_name.endsWith('assets.yaml')) return new AssetFile(file);
  return new LanguageFile(file);
};
