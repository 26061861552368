import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  IconButton,
  ListItemText,
  ListItemIcon,
  Icon,
  Menu,
  MenuItem,
} from '@mui/material';
import store from '~/store/index';
import filterStore from '~/store/filters';

import issueTemplate from './issueTemplate';

@observer
export default class OverflowMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => this.setState({ anchorEl: null });
  handleDelete = () => {
    this.props.entry.markRemoved();
    this.handleClose();
  };
  handleAppend = () => {
    this.handleClose();
    this.props.onAppend(this.props.entry);
  };

  render() {
    const { entry, readOnly } = this.props;
    const { anchorEl } = this.state;
    const { currentProject: project, currentFile: file } = store;
    const desc = encodeURIComponent(issueTemplate(entry, store));
    const title = encodeURIComponent(`📝 <Bereich> | <Beschreibung> | ${file.name}: ${entry.id}`);
    // eslint-disable-next-line max-len
    const issueUrl = `${project.webUrl}/issues/new?issuable_template=texting&issue[title]=${title}&issue[description]=${desc}`;

    return (
      <div>
        <IconButton
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          disabled={this.props.disabled}
          size="large"
        >
          <Icon>more_vert</Icon>
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          container={document.getElementById('popovers')}
        >
          <MenuItem onClick={this.handleClose} component="a" href={issueUrl} target="_blank">
            <ListItemIcon><Icon>bug_report</Icon></ListItemIcon>
            <ListItemText>Create Issue</ListItemText>
          </MenuItem>
          <MenuItem onClick={this.handleDelete} disabled={readOnly}>
            <ListItemIcon><Icon>delete</Icon></ListItemIcon>
            <ListItemText>Delete Entry</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={this.handleAppend}
            disabled={readOnly || filterStore.active.length !== 0}
          >
            <ListItemIcon><Icon>add</Icon></ListItemIcon>
            <ListItemText>Append Entry</ListItemText>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

OverflowMenu.propTypes = {
  entry: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  onAppend: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

OverflowMenu.defaultProps = {
  readOnly: false,
  disabled: false,
};
