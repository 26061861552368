import React from 'react';
import styled from '@emotion/styled';
import {
  Tooltip,
} from '@mui/material';
import {
  CheckCircleOutline,
  WarningAmberOutlined,
} from '@mui/icons-material';

export const checkOk = message => ({
  message,
  status: 'ok',
});

export const checkWarn = message => ({
  message,
  status: 'warn',
});

export const checkError = message => ({
  message,
  status: 'error',
});

const CheckWrap = styled.div`
  position: relative;
  border-radius: 8px;

  &.check-ok > .check-content > * {
    color: black;
    background-color: var(--theme-success);
  }

  &.check-warn > .check-content > * {
    color: black;
    background-color: var(--theme-warn);
  }

  .check-icon {
    position: absolute;
    top: 4px;
    right: 4px;
  }
`;

const Check = ({ children, check }) => {
  const result = check();
  // no check
  if (result === null) {
    return (
      <CheckWrap>
        {children}
      </CheckWrap>
    );
  }
  if (result.status === 'ok') {
    return (
      <Tooltip title={result.message}>
        <CheckWrap className="check-ok">
          <div className="check-content">{children}</div>
          <CheckCircleOutline className="check-icon" />
        </CheckWrap>
      </Tooltip>
    );
  }
  if (result.status === 'warn') {
    return (
      <Tooltip title={result.message}>
        <CheckWrap className="check-warn">
          <div className="check-content">{children}</div>
          <WarningAmberOutlined className="check-icon" />
        </CheckWrap>
      </Tooltip>
    );
  }
  return (
    <CheckWrap>
      <div className="check-content">{children}</div>
    </CheckWrap>
  );
};

export default Check;
