import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import {
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { observer } from 'mobx-react';
import store from '~/store';

const ChangeViewWrap = withTheme(styled.div`
  background-color: ${p => p.theme.palette.background.default};
  color: ${p => p.theme.palette.text.secondary};
  border-radius: 4px;
  padding-bottom: 5px;
  .fakeEntry {
    background-color: ${p => p.theme.palette.background.default};
    border: 1px solid ${p => p.theme.palette.divider};
    border-radius: 6px;
    margin: 8px;
    contain: content;
    overflow: hidden;
  }
  .statusBar {
    background-color: ${p => p.theme.palette.background.paper};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
  }
  .value, .id {
    padding: 15px;
    text-decoration: ${props => (props.removed ? 'line-through' : 'none')};
    color: ${p => p.theme.palette.text.primary};
  }
  .status {
    font-size: 0.8em;
    padding: 8px 13px;
    color: ${p => p.theme.palette.text.secondary};
    .material-icons { font-size: 1.1em; margin-right: 10px; padding-top: 2px; }
  }
  .value {
    color: ${p => p.theme.palette.text.secondary};
    background-color: ${p => p.theme.palette.background.default};
  }
`);

const ChangeTitle = withTheme(styled.h4`
  color: ${p => p.theme.palette.text.primary};
`);

const ChangeView = ({ entry, onChoose, viewOriginal }) => {
  if (!entry) return <div>Error displaying the changes.</div>;
  const status = (() => {
    const [icon, text] = (() => {
      if (viewOriginal) return ['loop', 'This was the entry before any changes'];
      if (entry.toBeRemoved) return ['delete', 'Entry was removed'];
      return ['edit', 'Entry value was modified'];
    })();

    return (
      <span>
        <Icon>{icon}</Icon>
        {text}
      </span>
    );
  })();

  return (
    <ChangeViewWrap removed={!viewOriginal && entry.toBeRemoved}>
      <div className="status">{status}</div>
      <div className="fakeEntry">
        <div className="statusBar">
          <div className="id">{entry.id}</div>
          <Button
            variant="outlined"
            color="primary"
            onClick={onChoose}
          >
            Choose
          </Button>
        </div>
        <div
          className="value"
          style={{ fontFamily: store.userSettings.displayFont }}
        >
          {viewOriginal ? entry.original.value || entry.value : entry.value}
        </div>
      </div>
    </ChangeViewWrap>
  );
};

ChangeView.propTypes = {
  entry: PropTypes.object.isRequired,
  onChoose: PropTypes.func.isRequired,
  viewOriginal: PropTypes.bool.isRequired,
};

@observer
class ConflictResolution extends React.Component {
  state = { originalOpen: false };
  chooseOriginal = () => {
    console.log('original');
    this.props.entry.revertEverything();
    this.props.entry.resolveConflict();
    this.props.onFinished();
  };
  chooseOwn = () => {
    this.props.entry.resolveConflict();
    this.props.onFinished();
  };
  chooseRemote = () => {
    const { entry } = this.props;
    if (entry.conflictsWith.toBeRemoved) {
      entry.markRemoved();
    } else {
      entry.unmarkRemoved();
      entry.setValue(entry.conflictsWith.value);
    }
    entry.resolveConflict();
    this.props.onFinished();
  };
  render() {
    const { entry } = this.props;
    const { originalOpen } = this.state;
    return (
      <Dialog
        maxWidth="md"
        open
        container={document.getElementById('popovers')}
      >
        <DialogTitle>
          {`Resolve conflicts for ${entry.id}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This entry has been remotely modified. Please choose a version to keep.
            You can also revert all your changes using
            &nbsp;
            <Icon>settings_backup_restore</Icon>
            &nbsp;
            if you do not want to deal with any conflict.
          </DialogContentText>

          {entry.original.value && (
            <div>
              <Button
                style={{ color: 'gray' }}
                onClick={() => this.setState({ originalOpen: !originalOpen })}
              >
                Before modification
                <Icon style={{ marginLeft: '10px' }}>
                  {originalOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                </Icon>
              </Button>
              {originalOpen
                && <ChangeView entry={entry} onChoose={this.chooseOriginal} viewOriginal />}
            </div>
          )}

          <ChangeTitle>Your changes</ChangeTitle>
          <ChangeView onChoose={this.chooseOwn} entry={entry} />
          <ChangeTitle>Remote changes</ChangeTitle>
          <ChangeView onChoose={this.chooseRemote} entry={entry.conflictsWith} />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onFinished} color="primary" autoFocus>
            cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConflictResolution.propTypes = {
  entry: PropTypes.object.isRequired,
  onFinished: PropTypes.func.isRequired,
};

export default ConflictResolution;
