import React from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";

import { useMatch } from "react-router-dom";
import { Toolbar } from "@mui/material";

import TedLogoSvg from "~/assets/ted_logo.svg";
import AvatarButton from "../UserButton";

const TedBar = styled(Toolbar)`
  background-color: #03a9f4;
  background-color: #6f858f;
  border-radius: 38px;
  margin: 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-content: center;
  .sub {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Logo = styled.img`
  height: 52px;
  width: auto;
  padding: 15px 10px;
`;

const TedAppBar = observer(() => {
  const inRootRoute = useMatch({
    path: "/",
    end: true,
  });
  const inRepoRoute = useMatch("/repo/*");
  if (inRepoRoute) return null;

  return (
    <div className="container mx-auto">
      <TedBar>
        <div className="sub">{inRootRoute && <Logo src={TedLogoSvg} />}</div>
        <div>
          <AvatarButton />
        </div>
      </TedBar>
    </div>
  );
});

export default TedAppBar;
