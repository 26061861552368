import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

import {
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Chip,
} from '@mui/material';

import store from '../store';

/**
 * Styled existing components
 */
const StyledChip = styled(Chip)`
  margin-right: .5em;
`;

const Bar = styled.header`
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: left;
  margin: 1em 0;
  > * {
    flex: 1 1 auto;
    margin: 0 1em;
  }
`;

// TODO: get rid of class
class FilterBar extends React.Component {
  render() {
    // get unique groups from projects
    const groups = new Set<string>();
    store.projects.projects.forEach(p => {
      if (p.group) {
        groups.add(p.group as string);
      }
    });

    return (
      <Bar>
        <div>
          <TextField
            autoFocus
            label="Search Projects"
            value={store.projects.textFilter}
            inputProps={{
              name: 'projectSearch',
              id: 'projectSearch',
            }}
            onChange={e => store.projects.setTextFilter(e.target.value)}
            fullWidth
          />
        </div>

        {!store.limitedEditPermission && (
          <div>
            <FormControl fullWidth>
              <InputLabel htmlFor="groupFilter">Groups</InputLabel>
              <Select
                multiple
                sx={{ height: '56px' }}
                label="Groups"
                value={store.projects.groupFilter}
                inputProps={{
                  name: 'groupFilter',
                  id: 'groupFilter',
                }}
                renderValue={selected => {
                  return selected.map(value => <StyledChip key={value} label={value} />);
                }}
                onChange={e => store.projects.groupFilter = e.target.value as any[]}
              >
                {Array.from(groups).map(group => (
                  <MenuItem value={group} key={group}>{group}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        <div>
          <FormControl fullWidth>
            <InputLabel>Sorting</InputLabel>
            <Select
              label="Sorting"
              value={store.projects.sorting}
              inputProps={{
                name: 'sorting',
                id: 'sorting',
              }}
              onChange={e => store.projects.sorting = e.target.value}
            >
              <MenuItem value="name:desc">Alphabetic</MenuItem>
              <MenuItem value="createdAt:asc">Newest created</MenuItem>
              <MenuItem value="createdAt:desc">Last created</MenuItem>
              <MenuItem value="lastActivityAt:asc">Newest activity</MenuItem>
              <MenuItem value="lastActivityAt:desc">Last activity</MenuItem>
            </Select>
          </FormControl>
        </div>
        {/* <ProjectSyncButton
          style={{ marginBottom: 3 }}
          onSyncComplete={() => store.projects.fetchProjects()}
        /> */}
      </Bar>
    );
  }
}

export default observer(FilterBar);
