import React from 'react';
import styled from '@emotion/styled';

const Outer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc((${p => p.ratio}) * 100%);
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;

  img {
    min-height: 100%;
    position: absolute;
  }
`;

const RatioBox = ({ children, ratio = '16:9', ...rest }) => (
  <Outer {...rest} ratio={ratio.split(':').reverse().join('/')}>
    <Content>{children}</Content>
  </Outer>
);

export default RatioBox;
