/* eslint-disable max-classes-per-file */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Loading from '~/project/Loading';
import { getLoginURL } from '~/api-client';

const LoginPage = observer(() => {
  useEffect(() => {
    const lastLoginAttempt = localStorage.getItem('ted.lastLoginAttempt');
    if (lastLoginAttempt) {
      // parse date
      const lastLoginAttemptDate = new Date(lastLoginAttempt);
      // check if it's been less than 5 seconds since last login attempt
      if (Date.now() - lastLoginAttemptDate.getTime() < 5000) {
        // show error
        throw new Error('Login loop detected.');
      }
    }

    // set current date in local storage
    window.localStorage.setItem('ted.lastLoginAttempt', new Date().toISOString());
    window.location.assign(getLoginURL());
  }, []);

  return (
    <Loading message="Redirecting to Sign In" />
  );
});

export default LoginPage;
