import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import withTheme from "@mui/styles/withTheme";

import {
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Icon,
  Switch,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import filtersStore from "~/store/filters";
import { SidebarBox, SingleOption, CaseToggle } from "./utils";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = function noop() {};

const filterOptions = {
  startsWith: {
    icon: "format_indent_increase",
    text: "starts with",
  },
  includes: {
    icon: "space_bar",
    text: "includes",
  },
  equals: {
    icon: "drag_handle",
    text: "equals",
  },
  notIncludes: {
    icon: "format_strikethrough",
    text: "excludes",
  },
  regex: {
    icon: "code",
    text: "regex",
  },
};

const OptionsGroup = withTheme(styled.div`
  padding: 12px 10px;
  color: ${(p) => p.theme.palette.text.secondary};
  margin-top: 20px;
  h4 {
    margin: 0;
    padding: 10px 0;
    font-family: "Roboto Condensed", monospace;
    color: ${(p) => p.theme.palette.text.primary};
  }
`);

const OptionsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledSelect = withTheme(styled(Select)`
  align-items: center;
  align-self: center;
  display: flex;
  background-color: ${(p) => p.theme.palette.background.paper};
  border-radius: 4px;
  font-size: 13px;
  height: 27px;
  overflow: hidden;
  &::before {
    border-bottom: 0 none;
  }
`);

const SelectRenderValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > :first-of-type {
    margin-right: 8px;
  }
  p {
    margin: 0;
  }
`;

const JustFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  height: 27px;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 13px;
  border: 1px solid var(--theme-box-shadow);
`;

const TextFilter = observer(({ label, name, onUpdate = noop }) => {
  const changeFilterHandler = (filter, value) => {
    // window.scrollTo(0, 0);
    filtersStore.set(filter, { enabled: value !== "", value });
    onUpdate();
  };
  const filter = filtersStore.get(name);

  return (
    <OptionsGroup key={name}>
      <OptionsTitle>
        <FormControlLabel
          control={<Checkbox checked={filter.enabled} />}
          label={label}
          onChange={(event) =>
            filtersStore.set(name, { enabled: event.target.checked })
          }
        />
        <JustFlex>
          <CaseToggle
            tabIndex={-1}
            onChange={(e) =>
              filtersStore.set(name, { caseSensitive: e.target.checked })
            }
            checked={filter.caseSensitive}
          />
          <StyledSelect
            value={filter.modifier}
            onChange={(event) =>
              filtersStore.set(name, { modifier: event.target.value })
            }
            renderValue={(value) => (
              <SelectRenderValue>
                <Icon>{filterOptions[value].icon}</Icon>
                <p>{filterOptions[value].text}</p>
              </SelectRenderValue>
            )}
          >
            {Object.keys(filterOptions).map((key) => (
              <MenuItem value={key} key={key}>
                <ListItemIcon>
                  <Icon>{filterOptions[key].icon}</Icon>
                </ListItemIcon>
                <ListItemText primary={filterOptions[key].text} />
              </MenuItem>
            ))}
          </StyledSelect>
        </JustFlex>
      </OptionsTitle>
      <Input
        type="text"
        className="dark:bg-zinc-900"
        value={filter.value}
        onChange={(e) => changeFilterHandler(name, e.target.value)}
        placeholder="text"
      />
    </OptionsGroup>
  );
});

TextFilter.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const SwitchFor = observer(({ name }) => {
  return (
    <Switch
      checked={filtersStore.get(name).enabled}
      onChange={(e) => filtersStore.set(name, { enabled: e.target.checked })}
    />
  );
});

@observer
class Filter extends React.Component {
  render() {
    return (
      <SidebarBox title="Filter">
        <SingleOption>
          <FormControlLabel
            control={<SwitchFor name="markedEntries" />}
            label="Highlighted entries"
          />
        </SingleOption>
        <SingleOption>
          <FormControlLabel
            control={<SwitchFor name="linterEntries" />}
            label="Entries with warnings"
          />
        </SingleOption>
        <SingleOption>
          <FormControlLabel
            control={<SwitchFor name="modifiedEntries" />}
            label="Modified entries"
          />
        </SingleOption>
        <TextFilter label="Key" name="id" />
        <TextFilter label="Value" name="value" />
      </SidebarBox>
    );
  }
}

export default Filter;
