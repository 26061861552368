import React from 'react';
import styled from '@emotion/styled';
import { ButtonBase, Tooltip, Checkbox } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { darken } from 'polished';

export const SingleOption = styled.div`
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DisplayNote = withTheme(styled.div`
  font-size: 0.8em;
  color: gray;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => darken(0.02, p.theme.palette.background.default)};
  height: 48px;
  div {
    flex: 1;
  }
`);

export const CustomToggle = withTheme(styled.span`
  display: inline-block;
  font-size: 14px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid ${p => p.theme.palette.divider};
  background-color: ${p => p.theme.palette.background.paper};
  color: ${p => p.theme.palette.text.primary};
  font-family: 'Source Code Pro', monospace;
  font-weight: bold;
  ${p => p.checked && `
    border: 1px solid ${p.theme.palette.secondary.main};
    background-color: ${p.theme.palette.secondary.main};
    color: white;
  `}
`);

export const CaseToggle = props => {
  return (
    <Tooltip title="Case-sensitive" placement="right">
      <Checkbox
        tabIndex={-1}
        icon={<CustomToggle>Aa</CustomToggle>}
        checkedIcon={<CustomToggle checked>Aa</CustomToggle>}
        {...props}
      />
    </Tooltip>
  );
};

export const WholeWordToggle = props => {
  return (
    <Tooltip title="Match whole word" placement="right">
      <Checkbox
        tabIndex={-1}
        icon={<CustomToggle>wo</CustomToggle>}
        checkedIcon={<CustomToggle checked>wo</CustomToggle>}
        {...props}
      />
    </Tooltip>
  );
};

export const RegexToggle = props => {
  return (
    <Tooltip title="Regex" placement="right">
      <Checkbox
        tabIndex={-1}
        icon={<CustomToggle>.*</CustomToggle>}
        checkedIcon={<CustomToggle checked>.*</CustomToggle>}
        {...props}
      />
    </Tooltip>
  );
};

const SidebarBoxWrap = styled.div`
  margin: 8px;
  background-color: var(--theme-panel);
  border-radius: 6px;
  box-shadow: 0 1px 2px #18181859;
  overflow: hidden;

  .head {
    padding: 10px;
    margin-bottom: 2px;
    line-height: 1;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #bebebe14;
    border-radius: 6px;
    margin: 2px;
  }

  .tools {
    margin-left: 10px;
  }

  &:first-of-type {
    margin-top: 0;
  }
`;

export const SidebarBox = ({ title, tools, children, ...rest }) => (
  <SidebarBoxWrap {...rest}>
    {title && (
      <div className="head">
        <div className="title">{title}</div>
        <div className="tools">{tools}</div>
      </div>
    )}
    <div className="box-content">{children}</div>
  </SidebarBoxWrap>
);

export const ToolButton = styled(ButtonBase)`
  border-radius: 38%;
  overflow: hidden;
  font-size: 1.2em;
  padding: 3px;
  margin-left: 4px;
  opacity: 0.8;

  .MuiSvgIcon-root { font-size: inherit }

  &:hover {
    background-color: var(--theme-panel);
    opacity: 1;
  }
`;
