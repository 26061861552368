import type { GenerateContentRequest } from "@google/generative-ai";
import { lexer } from "marked";
import { LanguageFile } from "~/store/modules/files";
import { baseURL } from "~/api-client";
import { AIChat } from "~/store/ai";

export const findFirstCodeBlock = (markdown: string) => {
  const tokens = lexer(markdown);

  for (const token of tokens) {
    if (token.type === "code") {
      return token.text;
    }
  }

  // probably not wrapped, find the first "["
  const firstOpenBracket = markdown.indexOf("[");
  const firstCloseBracket = markdown.indexOf("]");
  if (firstOpenBracket !== -1 && firstCloseBracket !== -1) {
    return markdown.slice(firstOpenBracket, firstCloseBracket + 1);
  }

  // try with "{"
  const firstOpenBrace = markdown.indexOf("{");
  const firstCloseBrace = markdown.indexOf("}");
  if (firstOpenBrace !== -1 && firstCloseBrace !== -1) {
    return markdown.slice(firstOpenBrace, firstCloseBrace + 1);
  }

  // fallback to text
  return markdown;
};

export const fileToMap = (file: LanguageFile) => {
  const entries = file.entries;
  const map = {};
  entries.forEach((entry) => {
    map[entry.id] = entry.value;
  });

  return map;
};

export const toolbox = async (file: LanguageFile, mode = "proofread") => {
  const res = await fetch(new URL(`/api/v1/ai/toolbox`, baseURL), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ file: fileToMap(file), mode }),
  }).then((res) => res.json());

  return res.output;
};

export const proofRead = async (file: LanguageFile, method = "single") => {
  const res = await fetch(new URL(`/api/v1/ai/proof-read`, baseURL), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ file: fileToMap(file), method }),
  }).then((res) => res.json());

  return res.output;
};

export const proofReadOneShot = async (file: LanguageFile) => {
  return proofRead(file, "single");
};

export const proofReadMerge = async (file: LanguageFile) => {
  return proofRead(file, "merge");
};

export const proofReadBulkToolbox = async (file: LanguageFile) => {
  return proofRead(file, "bulk");
};

export const chatPrompt = async (chat: AIChat) => {
  const conversation: GenerateContentRequest = {
    contents: chat.messages.map((m) => ({
      role: m.from === "user" ? "user" : "model",
      parts: [
        {
          text: m.content,
        },
      ],
    })),
  };

  const res = await fetch(new URL(`/api/v1/ai/chat`, baseURL), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      conversation,
      file: fileToMap(chat.file),
      fileName: chat.file.name,
    }),
  });

  return res.json();
};
