import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

import { AssetFile } from '~/store';
import AssetEntry from './AssetEntry';
import { FileError } from '../Utils';

const AssetList = styled.ul`
  list-style: none;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  transform: translateZ(0);
  contain: content;
`;


export default observer(({ file }: { file: AssetFile }) => {
  const { assets } = file;

  if (file.parseError) {
    return (
      <FileError
        name="Could not parse this file"
        message="Please contact the developer of this project"
        error={file.parseError.toString()}
      />
    );
  }

  return (
    <div
      style={{ height: 'calc(100vh - 73px)', contain: 'strict', overflowY: 'auto' }}
      className="custom-scrollbar"
    >
      <AssetList className="AssetList">
        {assets
          .filter(a => a.id) // TODO: throw error instead
          .map(asset => (
            <AssetEntry
              onUploadComplete={uploadId => asset.planCommit(uploadId)}
              asset={asset}
              key={asset.id}
            />
          ))}
      </AssetList>
    </div>
  );
});
