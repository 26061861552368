import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import withTheme from '@mui/styles/withTheme';

import { useProjectEnvs } from '~/queries';
import emptySvg from '~/assets/undraw_empty_xct9.svg';
import gitlabSvg from '~/assets/gitlab.svg';
import { List, ListItem, ListItemText, ListItemIcon, Skeleton, Stack } from '@mui/material';
import { HistoryOutlined, OpenInNewOutlined } from '@mui/icons-material';
import store from '~/store';
import { Favatar } from '../components/ProjectUtils';

const ProjectBoxWrap = withTheme(styled.div`
  .top {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h3 { margin: 1em; }
  }

  h4 {
    border-top: 1px solid #80808057;
    padding: 15px;
    padding-bottom: 10px;
    margin: 0;
  }
`);

const Img = styled.img`
  width: 24px;
  height: 24px;
`;

const ListItemLink = ({ url, icon, text, secondary = undefined, onClickLink = null }) => (
  <ListItem
    onClick={() => onClickLink && onClickLink(url)}
    button
    component="a"
    href={url}
    target="_blank"
  >
    <ListItemIcon>
      {icon}
    </ListItemIcon>
    <ListItemText primary={text} secondary={secondary} />
  </ListItem>
);

const EnvironmentsEmptyState = () => (
  <Stack style={{ margin: 15 }} spacing={2} justifyContent="center" alignItems="center">
    <img src={emptySvg} alt="" width="160" />
    <div>No deployments yet</div>
  </Stack>
);

const EnvironmentsList = ({ projectId }) => {
  const { data, isLoading } = useProjectEnvs(projectId);

  if (isLoading) {
    return (
      <List>
        <ListItem>
          <Skeleton width={210} />
        </ListItem>
      </List>
    );
  }

  if (!data) return <EnvironmentsEmptyState />;
  const envs = data.filter(env => !env.name.startsWith('review/') && env.external_url).map(e => ({
    ...e,
    external_url: e.external_url
      && e.external_url.replace('https://hydra-hub.io/', 'https://hub.hydra-newmedia.cloud/'),
  }));
  if (!envs) return <EnvironmentsEmptyState />;

  return (
    <List>
      {envs.map(env => (
        <ListItemLink
          key={env.id}
          url={env.external_url}
          icon={<OpenInNewOutlined />}
          text={env.name}
          // sadly not updated when deployed
          // secondary={<>Deployed <TimeAgo date={env.updated_at} /></>}
        />
      ))}
    </List>
  );
};

const GitLabIcon = () => <Img alt="gitlab logo" src={gitlabSvg} />;

const ProjectBox = observer(({ project }) => {
  const { name, avatarUrl, webUrl } = project;
  const historyUrl = `${webUrl}/commits/master?utf8=✓&search=📝`;

  return (
    <ProjectBoxWrap>
      <div className="top">
        <Favatar src={avatarUrl} fallback={name} />
        <h3>{name}</h3>
      </div>
      {!store.limitedEditPermission && (
        <List>
          <ListItemLink
            url={webUrl}
            icon={<GitLabIcon />}
            text="Open project page"
          />
          <ListItemLink
            url={historyUrl}
            icon={<HistoryOutlined />}
            text="View text change history"
          />
        </List>
      )}
      <h4>Deployed Environments</h4>
      <EnvironmentsList projectId={project.id} />
    </ProjectBoxWrap>
  );
});

export default ProjectBox;
