import React from "react";
import { observer } from "mobx-react";
import AceEditor from "react-ace";
import "brace/mode/plain_text";
import "brace/theme/github";
import "brace/theme/pastel_on_dark";
import store from "~/store";

export default observer((props) => {
  return (
    <AceEditor
      mode="plain_text"
      theme={
        store.userSettings.themeProps._base === "dark"
          ? "pastel_on_dark"
          : "github"
      }
      focus
      width="100%"
      maxLines={15}
      setOptions={{
        showInvisibles: false,
        wrap: true,
        indentedSoftWrap: false,
        showPrintMargin: false,
        showLineNumbers: true,
        showGutter: false,
        autoScrollEditorIntoView: false,
        highlightActiveLine: false,
        fontSize: "inherit",
      }}
      editorProps={{ $blockScrolling: true }}
      commands={[
        {
          name: "Exit edit mode",
          bindKey: { win: "esc", mac: "esc" },
          exec: () => {
            if (props.onExit) props.onExit();
          },
        },
      ]}
      {...props}
    />
  );
});
