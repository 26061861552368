import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import {
  Avatar,
  ButtonBase,
  Icon,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { AccountCircleRounded } from "@mui/icons-material";
import { Navigate } from "react-router-dom";

import { getLogoutURL } from "../api-client";
import store from "../store";
import Help from "../pages/Help";

@observer
class AvatarMenu extends React.Component {
  state = {
    anchorEl: null,
    helpOpen: false,
  };

  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => this.setState({ anchorEl: null });
  toggleHelp = () => this.setState((prev) => ({ helpOpen: !prev.helpOpen }));
  handleLogout = async () => {
    store.user = { isAuthenticated: false };
    store.projects.clear();
    window.location.replace(getLogoutURL());
  };
  openHelp = () => {
    this.handleClose();
    this.toggleHelp();
  };

  render() {
    const { anchorEl, helpOpen } = this.state;
    const { avatar } = this.props;

    if (!store.userIsAuthenticated) return <Navigate to="/login" />;

    return (
      <div className="ted-avatarMenu">
        <ButtonBase
          aria-owns={anchorEl ? "account-dialog" : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          variant="contained"
          color="primary"
        >
          {avatar}
        </ButtonBase>
        <Menu
          id="account-dialog"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          container={document.getElementById("popovers")}
        >
          <MenuItem onClick={this.openHelp}>
            <ListItemIcon>
              <Icon>help</Icon>
            </ListItemIcon>
            <ListItemText primary="Help" />
          </MenuItem>
          {store.user.authProvider === "gitlab" && (
            <MenuItem
              onClick={this.handleClose}
              component="a"
              href="https://gitlab.hydra-newmedia.cloud/-/profile"
              target="_blank"
            >
              <ListItemIcon>
                <Icon>account_circle</Icon>
              </ListItemIcon>
              <ListItemText primary="Account Settings" />
            </MenuItem>
          )}
          <MenuItem onClick={this.handleLogout}>
            <ListItemIcon>
              <Icon>power_settings_new</Icon>
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
        {helpOpen && <Help open={helpOpen} toggleDialog={this.toggleHelp} />}
      </div>
    );
  }
}

AvatarMenu.propTypes = {
  avatar: PropTypes.node.isRequired,
};

export default observer(() => {
  const { user } = store;
  const fallbackAvatar = <AccountCircleRounded style={{ fontSize: "2em" }} />;
  if (!user.avatarUrl) return <AvatarMenu avatar={fallbackAvatar} />;

  const avatar = (
    <Avatar>
      <img style={{ width: "100%" }} src={user.avatarUrl} alt="User Menu" />
    </Avatar>
  );
  return <AvatarMenu avatar={avatar} />;
});
