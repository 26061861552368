import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Icon, IconButton, Dialog } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { observer } from 'mobx-react';
import store from '../store';

const DialogWrapper = withTheme(styled.div`
  padding: 1em;
  h2, th {
    color: ${p => p.theme.palette.text.primary};
  }
  color: ${p => p.theme.palette.text.secondary};
  ${p => console.log(p.theme)};
`);

const DialogHeader = styled.header`
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h2 { margin: .5em 0; }
  p { margin: 0; }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th, td {
    text-align: left;
    padding: .5em;
  }
  tbody tr {
    border-top: 1px solid rgba(0,0,0,.16);
    background-color: transparent;
    transition: background-color .25s;
    will-change: background-color;
    &:hover {
      background-color: rgba(0,0,0,.08);
    }
  }
  td:first-of-type {
    display: flex;
    span {
      background-color: rgba(0, 0, 0, 0.12);
      padding: .25em .5em;
      border-radius: 4px;
      & ~ span {
        margin-left: 14px;
        position: relative;
        ::before {
          content: '+';
          display: block;
          position: absolute;
          left: -12px;
        }
      }
    }
  }
`;

const Help = observer(({ open, toggleDialog }) => {
  const items = {
    '↑': 'Focus previous entry',
    '↓': 'Focus next entry',
    e: 'Enable Edit-Mode',
    Esc: 'Exit out of Edit-Mode (always works)',
    ...(store.limitedEditPermission ? {} : {
      a: 'Append a new entry',
      'shift+a': 'Prepend a new entry',
      del: 'Remove an entry (toggles)',
    }),
    u: 'Undo changes to entry',
  };

  return (
    <Dialog
      id="help-dialog"
      open={open}
      onClose={toggleDialog}
      container={document.getElementById('popovers')}
    >
      <DialogWrapper>
        <DialogHeader>
          <div>
            <h2>Help</h2>
            <p>
              These shortcuts only work if focusing an entry. Click on any entry to focus.
              <br />
              They do not work if you are currently in editing mode.
            </p>
          </div>
          <IconButton onClick={toggleDialog} size="large">
            <Icon>close</Icon>
          </IconButton>
        </DialogHeader>

        <Table>
          <thead>
            <tr>
              <th>Keyboard Shortcut</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(items).map(key => (
              <tr key={key}>
                <td>{key.split('+').map(chunk => (<span key={chunk}>{chunk}</span>))}</td>
                <td>{items[key]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </DialogWrapper>
    </Dialog>
  );
});

Help.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
};

export default Help;
