import { makeAutoObservable } from 'mobx';

export default class Actions {
  active = null;
  params = undefined;
  map = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  add(name, action) {
    this.map.set(name, action);
  }

  run(name, params) {
    this.active = name;
    this.params = params;
  }
}

