import React from 'react';

import { observer } from 'mobx-react';
import { Dialog } from '@mui/material';
import store from '../store';

import Export from './actions/Export';
import Revert from './actions/Revert';
import Import from './actions/Import';

const actions = {
  export: Export,
  revert: Revert,
  import: Import,
};

const Actions = observer(() => {
  const { active, params } = store.actions;
  if (!active) return null;

  const ActionComponent = actions[active];

  const close = () => {
    store.actions.active = null;
  };

  const display = ActionComponent.display || 'dialog';

  if (display === 'raw') {
    return <ActionComponent onDone={close} {...params} />;
  }

  return (
    <Dialog
      id="action-dialog"
      open
      onClose={close}
      container={document.getElementById('popovers')}
    >
      <ActionComponent onDone={close} {...params} />
    </Dialog>
  );
});

export default Actions;
