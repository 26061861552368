import {
  Button, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

export default observer(({ file, onDone }) => {
  const handleReset = async () => {
    await file.undoChanges();
    onDone();
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">Undo all local changes in this file?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This will undo all local changes you did to this file.
          The latest version from GitLab will be pulled.
          <br />
          <br />
          <strong>This action cannot be undone</strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDone} color="primary">
          Cancel
        </Button>
        <Button onClick={handleReset} color="primary" autoFocus>
          Undo Changes
        </Button>
      </DialogActions>
    </>
  );
});
