import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Icon, Button } from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import store from "../store";

const ErrorWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px);
  padding: 1em;
  overflow: hidden;
  svg {
    display: block;
    width: 100%;
  }

  pre {
    color: lightgray;
    white-space: pre-wrap;
  }
`;

export const DefaultError = ({ fail }) => {
  const user = store.userIsAuthenticated ? store.user : undefined;
  if (process.env.NODE_ENV !== "production") {
    return (
      <ErrorWrap>
        <Icon>warning_sign</Icon>
        <h3>Fix this!</h3>
        <pre>{fail.cause && fail.cause.toString()}</pre>
      </ErrorWrap>
    );
  }
  return (
    <ErrorWrap>
      <Icon>warning_sign</Icon>
      <h3>{fail.title}</h3>
      <p>{fail.help}</p>
      <Button
        variant="outlined"
        onClick={() => Sentry.showReportDialog({ user })}
      >
        Provide feedback
      </Button>
      {fail.lastAction && (
        <>
          <h5>Last Action</h5>
          <pre>{fail.lastAction}</pre>
        </>
      )}
    </ErrorWrap>
  );
};

const AuthError = ({ code, onClick }) => {
  if (code === 401) {
    onClick();
    return <Navigate to="/login" />;
  }
  return (
    <ErrorWrap>
      <div className="bg-panel rounded-md shadow-md p-4 text-center">
        <Icon className="text-xl text-amber-500">warning_sign</Icon>
        <h3 className="my-4">You are not authorized to access this page.</h3>
        <Button component={Link} to="/" variant="outlined" onClick={onClick}>
          Back to homepage
        </Button>
      </div>
    </ErrorWrap>
  );
};

DefaultError.propTypes = {
  fail: PropTypes.object.isRequired,
};

export default class ErrorBoundary extends React.Component {
  state = { error: null };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  // eslint-disable-next-line consistent-return
  componentDidCatch(error, info) {
    // skip 403 and 401 errors
    if (error.response?.status === 403 || error.response?.status === 401) {
      return;
    }

    // skip sentry when testing locally
    if (process.env.NODE_ENV !== "production") {
      console.error(error, info);
      return;
    }

    // send error to sentry
    Sentry.withScope((scope) => {
      Object.keys(info).forEach((key) => {
        scope.setExtra(key, info[key]);
      });
      if (store.userIsAuthenticated) scope.setUser(store.user);
      Sentry.captureException(error);
    });
  }

  clearError = () => {
    this.setState({ error: null });
  };

  render() {
    if (this.state.error) {
      if (
        this.state.error.response?.status === 403 ||
        this.state.error.response?.status === 401
      ) {
        return (
          <AuthError
            code={this.state.error.response.status}
            onClick={this.clearError}
          />
        );
      }

      const fail = {
        title: "Sorry, something broke.",
        help: [
          "We have been notified.",
          "You can provide additional feedback to speed up the bug hunt.",
        ].join(" "),
        cause: this.state.error,
      };
      return <DefaultError fail={fail} />;
    }

    return <>{this.props.children}</>;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

ErrorBoundary.defaultProps = {
  children: null,
};
