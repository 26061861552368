import { makeAutoObservable } from 'mobx';
import prettyBytes from 'pretty-bytes';
import { PercentCrop } from 'react-image-crop';

import type { TMP_FE_AssetView } from '@app/types';
import api from '~/api-client';

const FILE_MAP = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  webp: 'image/webp',
  svg: 'image/svg+xml',
};

// stolen from
// https://stackoverflow.com/questions/1186414/whats-the-algorithm-to-calculate-aspect-ratio
function gcd(a, b) {
  return (b === 0) ? a : gcd(b, a % b);
}

function typeFromFileName(name: string) {
  const fileExt = name.split('.').pop();
  const type = FILE_MAP[fileExt];

  return type;
}

class AssetMeta {
  size: number;
  width: number;
  height: number;
  type: string;

  constructor(data) {
    Object.assign(this, data);

    makeAutoObservable(this);
  }

  get aspectRatio() {
    if (!this.width || !this.height) return null;
    const r = gcd(this.width, this.height);
    return `${Math.round(this.width / r)}:${Math.round(this.height / r)}`;
  }
  get resolution() {
    if (!this.width || !this.height) return null;
    return `${this.width} ✕ ${this.height}`;
  }
  get humanSize() {
    return this.size ? prettyBytes(this.size) : 'unknown';
  }
}


export default class Asset {
  content: TMP_FE_AssetView;
  needsCommit = true;
  currentMetaData: AssetMeta = null;
  originalAsset: AssetMeta = null;
  private replaceWithUploadId: string | null;
  uploadPreview: string = null;
  cropped = false;

  constructor(content: TMP_FE_AssetView) {
    this.content = content;
    this.currentMetaData = new AssetMeta({
      size: content.size,
      type: typeFromFileName(content.file_path),
    });

    makeAutoObservable(this);
  }

  get id() {
    return this.content.notFound ? this.content.path : this.content.blob_id;
  }

  get preview(): string | null {
    if (this.content.notFound) return null;
    if (this.uploadPreview) return this.uploadPreview;
    return this.content.signed_url;
  }

  get modified() {
    return Boolean(this.needsCommit && this.replaceWithUploadId);
  }
  get aspectRatio() {
    return this.currentMetaData.aspectRatio;
  }
  get resolution() {
    return this.currentMetaData.resolution;
  }
  get humanSize() {
    return this.currentMetaData.humanSize;
  }

  get ted_upload_id() {
    return this.replaceWithUploadId;
  }

  async serverCrop(crop: PercentCrop) {
    const { width, height } = this.currentMetaData;

    const uploadId = this.ted_upload_id;
    if (!uploadId) throw new Error('Need ted_upload_id to crop');

    const { x, y, width: cropWidth, height: cropHeight } = crop;

    const cropData = {
      x: Math.floor((x * width) / 100),
      y: Math.floor((y * height) / 100),
      width: Math.floor((cropWidth * width) / 100),
      height: Math.floor((cropHeight * height) / 100),
      scaleWidth: this.originalAsset.width,
      scaleHeight: this.originalAsset.height,
      convertTo: this.originalAsset.type,
    };
    const { data } = await api.post(`/assets/${uploadId}/crop`, cropData);
    this.setUploadPreview(data.preview);
    this.currentMetaData = new AssetMeta({
      ...this.currentMetaData,
      width: data.meta.width,
      height: data.meta.height,
      size: data.meta.size,
    });
    this.cropped = true;
  }

  setUploadPreview(preview) {
    this.uploadPreview = preview;
  }

  planCommit(uploadId) {
    this.needsCommit = true;
    this.replaceWithUploadId = uploadId;
  }

  reset() {
    this.needsCommit = false;
    this.replaceWithUploadId = null;
    if (this.originalAsset !== null) {
      this.currentMetaData = this.originalAsset;
    }
    this.originalAsset = null;
    this.uploadPreview = null;
    this.cropped = false;
  }

  storeOriginal() {
    if (this.originalAsset !== null) return;
    this.originalAsset = new AssetMeta({ ...this.currentMetaData });
  }

  markCommitted() {
    this.needsCommit = false;
    this.originalAsset = null;
    this.cropped = false;
  }
}

